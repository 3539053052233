import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import ReactGA from 'react-ga';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { API_PATH, UserInput, Vendors } from '../../Constants';
import PropTypes from "prop-types";
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
import YouTube from 'react-youtube';
import './desti-Info.scss';
import SearchModal from './searchModal';

const adultsOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' }
];

class DestInfo extends Component {

    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props){
        super(props);
        this.state = {
            userInput: UserInput,
            selectedOption: null,
            source: null,
            destination: null,
            count: { value: '2', label: '2' },
            startDate: new Date(new Date().setDate(new Date().getDate() + 15)),
            endDate: new Date(new Date().setDate(new Date().getDate() + 17)),
            destinationList: [],
            sourceList: [],
            openModal: false,
            searchModal: false,
            vendorDetail: null
        }
    }

    componentWillMount = () => {
        this.setVendor();
    }

    componentDidMount = () => {
        this.searchSource();
        ReactGA.ga('send', 'pageview', '/date-info');
    }

    onStartChange = (startDate) => {
        if(startDate) {
            this.setState({startDate}, () => {
                this.setLocalStorage();
            });
        }
    }

    onEndChange = (endDate) => {
        if(endDate) {
            this.setState({endDate}, () => {
                this.setLocalStorage();
            });
        }
    }

    // _onSelect = (e) => {
    //     if(e && e.value) {
    //         this.setState({count: e}, () => {
    //             this.setLocalStorage();
    //         });
    //     }
    // }

    setLocalStorage = () => {
        let userInput = JSON.parse(localStorage.getItem('userInput'));
        if(!userInput) {
            userInput = UserInput;
        }
        if (this.state.vendorDetail) {
            userInput.Agent.agent_id = this.state.vendorDetail.agentId;
        }
        userInput.source = this.state.source;
        userInput.destination = this.state.destination;
        userInput.adults.count = 2;
        userInput.startDateTime = moment(this.state.startDate).format('YYYY-MM-DDT00:00:00.000+05:30');
        userInput.endDateTime = moment(this.state.endDate).format('YYYY-MM-DDT00:00:00.000+05:30');
        this.setState({userInput}, () => {
            localStorage.setItem('userInput', JSON.stringify(userInput));
        });
    }

    handleSourceChange = source => {
        this.setState({source}, () => {
            this.setLocalStorage();
        });
    };

    handleDestinationChange = (destination) => {
        this.setState({
            destination,
            openSearchModal: false
        }, () => {
            this.setLocalStorage();
        });
    };

    searchSource() {
        const obj = {
            inputText: '',
            locationType: 'source',
            agent_id: this.state.vendorDetail.agentId
        };
        fetch(API_PATH + 'getBestTripLocation', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then((data) => {
            if(data && data.length) {
                data = data.map((item) => {
                    item['value'] = item['name'];
                    item['label'] = item['name']
                    return item;
                })
            }
            this.setState({ sourceList: data });
            this.setState({source: data[0]});
        }).catch(console.log)
    }

    navigateToNext = () => {
        if (this.state.destination) {
            this.setLocalStorage();
            this.context.router.history.push("/my-plans");
        } else {
            alert('Please Select Destination')
        }
    }

    scrollTo = () => {
        const elmnt = document.getElementById('demo');
        if(elmnt) {
            elmnt.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    onDestinationFocus = () => {
        this.setState({
            openSearchModal: true
        })
    }

    openModal = () => {
        this.setState({openModal: true});
    }

    closeModal = () => {
        this.setState({openModal: false});
    }

    handelSearchModalClose = () => {
        this.setState({openSearchModal: false});
    }

    setVendor = () => {
        const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
        this.setState({vendorDetail}, ()=> {
            this.setLocalStorage();
        });
    }

    render() {
        const pStyle = {
            fontSize: '32px',
            textAlign: 'center',
            fontWeight: 'bold'
        };
        const opts = {
            height: '260'
        };
        let destination;
        if (!this.state.destination) {
            destination = ""
        } else {
            destination = this.state.destination.name
        }
        return (
            <section className="dest-info p-0 bgImg" >
                {/* <div class="overlay"></div> */}
                <div className="px-3 py-5 h-100 bgOverlay">
                    <div className="col-12 p-0 text-center heading-container">
                        <p className="m-0 pb-3 text-center mx-5 heading">Plan your trip</p>
                    </div>
                    { this.state.openSearchModal &&
                        <SearchModal onModalClose={this.handelSearchModalClose} onSelectDestination={this.handleDestinationChange} />
                    }
                    <div className="col-12 p-0 pb-5 text-center">
                            <div className="row m-0 my-4 text-left align-items-center border-bottom destination-container">
                                <div className="col-12 p-0 text-left">
                                    <input type="text" value={destination}  onFocus={ this.onDestinationFocus } readOnly className="form-control" placeholder="Where would you like to travel?"></input>
                                </div>
                            </div>
                            { this.state.vendorDetail.destinationVideos && this.state.vendorDetail.destinationVideos.active &&
                            <div className="col-12 text-right">
                                <span className="dest-text" onClick={() => this.openModal() } >Help me choose a destination</span>
                            </div>
                            }
                            <div className="row m-0 my-4 text-left align-items-center border-bottom">
                                <div className="col-6 p-0">Start</div>
                                <div className="col-6 p-0">End</div>
                            </div>
                            <div className="row m-0 my-4 text-left align-items-center border-bottom">
                                <div className="col-6 p-0 text-left">
                                    <DatePicker
                                        customInput={<CustomInput />}
                                        showPopperArrow={false}
                                        selected={this.state.startDate}
                                        minDate={new Date(new Date().setDate(new Date().getDate()))}
                                        onChange={this.onStartChange}
                                        dateFormat="dd/MM/yyyy"
                                        withPortal
                                    />
                                </div>
                                <div className="col-6 p-0 text-left">
                                    <DatePicker
                                        customInput={<CustomInput />}
                                        showPopperArrow={false}
                                        selected={this.state.endDate}
                                        minDate={this.state.startDate}
                                        maxDate={new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 4))}
                                        onChange={this.onEndChange}
                                        dateFormat="dd/MM/yyyy"
                                        withPortal
                                    />
                                </div>
                            </div>
                            {/* <div className="row m-0 my-4 text-left align-items-center border-bottom">
                                <div className="col-6 p-0">People</div>
                                <div className="col-6 p-0 text-left">
                                    <Select
                                        value={this.state.count}
                                        onChange={this._onSelect}
                                        options={adultsOptions}
                                        isSearchable={false}
                                    />
                                </div>
                            </div> */}
                        </div>
                    <div className="col-12 text-center nav-bar">
                        <span onClick={() => this.navigateToNext() } className="white-text bg-warning w-100 d-block py-2 text-decoration-none" >Create a Plan for Me</span>
                    </div>
                </div>
                { this.state.openModal &&
                    <div className="section-detail px-3">
                        <span onClick={ this.closeModal } style={{color: '#333', position: 'absolute', right:'16px', top: '8px', cursor: 'pointer'}}>X</span>
                        <article id="demo my-3">
                            <div className="d-flex justify-content-center">
                                <p className="m-0 pb-3 text-center mx-5" style={pStyle}>Get Inspired</p>
                            </div>
                            { this.state.vendorDetail &&
                                <AwesomeSlider cssModule={AwesomeSliderStyles}>
                                    {this.state.vendorDetail.destinationVideos.videoList.map((id) => (
                                        <div className="video-box d-flex p-0 h-100 w-100" key={id} >
                                            <YouTube videoId={id} opts={opts} onReady={this._onReady}/>
                                        </div>
                                    ))}
                                </AwesomeSlider>
                            }
                        </article>
                        <div className="col-12 text-center nav-bar">
                            <span onClick={ this.closeModal } className="white-text bg-warning w-100 d-block py-2 text-decoration-none" >Made my mind</span>
                        </div>
                    </div>
                }
            </section>
        )
    }
}
export default DestInfo


const CustomInput = React.forwardRef((props,ref) => {
    return (
        <input
            onClick={props.onClick}
            value={props.value}
            type="text"
            readOnly={true}
            ref={ref}
        />
    )

})