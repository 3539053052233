import React, { Component } from 'react';
import ReactGA from 'react-ga';
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/src/core/styles.scss';
import NoImage from '../../assets/images/no-image.png';
import './event.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Vendors } from '../../Constants';

class Eventdetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventdetail: null,
            destinationList: [],
            imageUrlArray: [],
            tabStatus: 'desc',
            vendorDetail: null
        };
    }

    componentWillMount() {
        this.setVendor();
        ReactGA.ga('send', 'pageview', '/event-detail');
        this.setState({eventdetail: this.props.eventDetail});
        const extraPhotos = this.getPhotos(this.props.eventDetail);
        this.setState({imageUrlArray: extraPhotos})
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    setVendor = () => {
        const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
        this.setState({vendorDetail, destinationList: vendorDetail.destinationList});
    }

    getPhotos(eventDetail){
        let extraPhoto = [];
        if(eventDetail) {
            if(eventDetail.extraPhoto && eventDetail.extraPhoto.length) {
                extraPhoto = eventDetail.extraPhoto;
            } else {
                extraPhoto.push({ finalURL: NoImage});
            }
        }
        return extraPhoto;
    }

    onTabChange = (status) => {
        this.setState({tabStatus:status })
    }

    render() {
        const eventDetail = this.state.eventdetail;
        return (
            <div className="event-detail-body px-3">
                {eventDetail &&
                    <div className="p-0">
                        <AwesomeSlider cssModule={AwsSliderStyles}>
                            {this.state.imageUrlArray.map((plan, index) => (
                                <div key={index} data-src={plan.finalURL} />
                            ))}
                        </AwesomeSlider>
                        <div className="mt-5 mb-3">
                            <div className="desc-container px-3" id="desc">
                                <h3>Description</h3>
                                <p>
                                    {( eventDetail.merchant_description ) ? eventDetail.merchant_description : ''}
                                </p>
                            </div>
                            <div className="review-container px-3" id="reviews">
                                <h3>Google reviews</h3>
                                { eventDetail.rating && eventDetail.rating.length && eventDetail.rating[0].count &&
                                    <div className="col-12 p-0 text-center">
                                        <span className="rting">{ eventDetail.rating[0].count }</span>
                                        <div className="d-flex justify-content-center">
                                            <div className="ratting" >
                                                <FontAwesomeIcon className={((eventDetail.rating[0].count).substring(0,1) >= 1) ? "checked" : "" } icon="star" size="xs" />
                                                <FontAwesomeIcon className={((eventDetail.rating[0].count).substring(0,1) >= 2) ? "checked" : "" } icon="star" size="xs" />
                                                <FontAwesomeIcon className={((eventDetail.rating[0].count).substring(0,1) >= 3) ? "checked" : "" } icon="star" size="xs" />
                                                <FontAwesomeIcon className={((eventDetail.rating[0].count).substring(0,1) >= 4) ? "checked" : "" } icon="star" size="xs" />
                                                <FontAwesomeIcon className={((eventDetail.rating[0].count).substring(0,1) >= 5) ? "checked" : "" } icon="star" size="xs" />
                                            </div>
                                        </div>
                                        <div>{ eventDetail.review[0].review } reviews</div>
                                    </div>
                                }
                                <div className="col-12 p-0">
                                    { eventDetail && eventDetail.extraReview && eventDetail.extraReview.length &&
                                        eventDetail.extraReview.map((review, index) =>
                                        <article key={index} className="row m-0" >
                                            <div className="profile col-2 p-0" >
                                                <img src={review.profilePhotoUrl} alt="Smiley face" />
                                            </div>
                                            <div className="review-detail col-10 p-0">
                                                <div className="author-name">
                                                    <a href={review.authorUrl} rel="noopener noreferrer" target="_blank">{ review.authorName }</a>
                                                </div>
                                                <div className="d-flex">
                                                    { review && review.rating &&
                                                        <div className="ratting pr-4" >
                                                            <FontAwesomeIcon className={((review.rating).substring(0,1) >= 1) ? "checked" : "" } icon="star" size="xs" />
                                                            <FontAwesomeIcon className={((review.rating).substring(0,1) >= 2) ? "checked" : "" } icon="star" size="xs" />
                                                            <FontAwesomeIcon className={((review.rating).substring(0,1) >= 3) ? "checked" : "" } icon="star" size="xs" />
                                                            <FontAwesomeIcon className={((review.rating).substring(0,1) >= 4) ? "checked" : "" } icon="star" size="xs" />
                                                            <FontAwesomeIcon className={((review.rating).substring(0,1) >= 5) ? "checked" : "" } icon="star" size="xs" />
                                                        </div>
                                                    }
                                                    <div className="dehysf">{ review.relativeTimeDescription }</div>
                                                </div>
                                                <p>{review.text}</p>
                                            </div>
                                        </article>
                                    )}
                                </div>
                            </div>
                            {eventDetail.similarMerchants && eventDetail.similarMerchants.length &&
                                <div className="similar-container col-12 px-3" id="similar">
                                    <h3>similar Hotels</h3>
                                    <aside className="similar-grid">
                                        {eventDetail.similarMerchants.map((merchants, index) =>
                                            <div key={index} className="card">
                                                <img alt="thumbPhotoURL" src={merchants.photos.thumbPhotoURL} />
                                                <div className="card-body">
                                                    <div className="card-title h5" >{merchants.name}</div>
                                                    <div className="card-text">
                                                        Some quick example text to build on the card title and make up the bulk of
                                                        the card's content.
                                                    </div>
                                                    <button className="btn btn-primary">Select</button>
                                                </div>
                                            </div>
                                        )}
                                    </aside>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}
export default Eventdetail