import React, { Component } from 'react'
import PropTypes from 'prop-types';
import './menuContent.scss'
import { Link } from 'react-router-dom';
import { Vendors } from '../../Constants';
import logo from '../../assets/images/logo.png';
import googlePoweredLogo from '../../assets/images/powered_by_google.png';


class MenuContent extends Component {

  static propTypes = {
    closeCallback: PropTypes.func.isRequired
  }

  componentWillMount = () => {
    this.setVendor();
  }

  setVendor = () => {
    const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
    this.setState({vendorDetail});
  }

  render() {
    return (
      <div className="menu">
        <div className="logo-section">
          <img src={ this.state.vendorDetail ? this.state.vendorDetail.logo : logo } onClick={this.navigateToPayment} alt="logo" ></img>
        </div>
        <div className="menu-item" onClick={this.props.closeCallback}>
          { this.state.vendorDetail.menu && this.state.vendorDetail.menu.mainMenu.map((menu, index) =>
              <span key={index} ><Link to={menu.routeLink}> { menu.label }</Link></span>
          )}
        </div>
        <div className="footer-part">
          { this.state.vendorDetail.menu && this.state.vendorDetail.menu.footer.map((val, valIndex) =>
            <section key={valIndex}>
              <h3>{ val.name }</h3>
                <ul>
                  {  val.childrens && val.childrens.map((item, itemIndex) =>
                    <li key={itemIndex}>{ item.label }</li>
                  )}
                  { valIndex === 0 &&
                  <li>
                    <a target="_blank" href="https://www.google.com/intl/en/policies/terms" >Google’s Terms of Service</a>
                  </li>
                  }{ valIndex === 0 &&
                  <li>
                    <a target="_blank" href="https://www.google.com/policies/privacy" >Google's Privacy Policy</a>
                  </li>
                  }
                </ul>
            </section>
          )}
          { googlePoweredLogo && 
            <img src={googlePoweredLogo} alt="Google logo" />
          }
        </div>
      </div>
    )
  }
}

export default MenuContent
