import React, { Component } from 'react';
import './myplan.scss';
import { API_PATH, UserInput } from '../../Constants';
import PropTypes from "prop-types";
import Timeline from "../timeline/index";
import Loader from 'react-loader-spinner';
import ReactGA from 'react-ga';
import Eventdetail from '../eventdetail/eventDetail';
import { StickyContainer } from 'react-sticky';
import Tabs from '../eventdetail/Tabs';
import CustomizePlan from '../customizeplan/customizeplan';

class MyPlan extends Component {

  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
      super(props, context);
      this.state = {
        backEventObj: {
          open: false,
          merchants: [],
          plan: null,
          paymentList: [],
          userInput: UserInput,
          openModal: false,
          eventDetail: null,
          stickyHeader: true,
          activeTab: 'desc',
          showCustomizePage: false
        },
        headerHeight: 0,
        tabList: [
          {label:'Description', key: 'desc'},
          {label:'Reviews', key: 'reviews'}
        ],
        loading: true,
        convertedList: []
      };
  }

  componentDidMount = () => {
    ReactGA.ga('send', 'pageview', '/my-plans');
    const location = this.props.location;
    var queryParam = location.search ? new URLSearchParams(location.search) : new URLSearchParams();
    if(queryParam.get('plan_referenceNo')) { 
      this.getPlanByReferenceNumber();
    } else {
      const userInput = JSON.parse(localStorage.getItem('userInput'));
      if(userInput) {
        this.setState({backEventObj: {userInput}}, () => {
          this.getCalendarEvent();
        })
      } else {
        this.context.router.history.push("/");
      }
    }
  }
  
  getPlanByReferenceNumber = () => {
    const location = this.props.location;
    var queryParam = location.search ? new URLSearchParams(location.search) : new URLSearchParams();
    if(queryParam.get('plan_referenceNo')) { 
      const obj = {
        Plan: {
          plan_referenceNo:queryParam.get('plan_referenceNo')
        }
      };
      if(queryParam.get('id')) {
        obj.Plan['plan_hash_referenceNo'] = queryParam.get('id');
      }
      fetch(API_PATH + 'getPlan', {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
    .then((data) => {
        if(data && data.merchants){
          this.setState({ backEventObj: data }, ()=> {
            this.convertReponse();
          })
        } else {
          this.setState({loading: false});
        }
    })
    .catch(console.log)
    }
  }

  handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    if( scrollTop > 160 ) {
      this.setState({stickyHeader: true});
    } else {
      this.setState({stickyHeader: false});
    }
  };

  componentWillMount = () => {
    this.setState({activeTab: 'desc'});
    window.addEventListener('scroll', this.handleScroll);
    sessionStorage.removeItem('isCustomize');
  }

  getCalendarEvent = () => {
    fetch(API_PATH + 'getPlan', {
        method: 'POST',
        body: JSON.stringify(this.state.backEventObj.userInput),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
    .then((data) => {
        if(data && data.merchants){
          this.setState({ backEventObj: data }, ()=> {
            this.convertReponse();
          })
        } else {
          this.setState({loading: false});
        }
    })
    .catch(console.log)
  }

  convertReponse = () => {
    let list = [];
    this.state.backEventObj.merchants.forEach((item, index) => {
      if(item) {
        let startTime = item.service[0].slot[0].startDateTime;
        if(item.l2Category.l1category.category === 'Hotel') {
          if (item["service"][0].slot[0].displayEvents && item["service"][0].slot[0].displayEvents.length) {
            item["service"][0].slot[0].displayEvents.forEach((hotelObj) => {
              list.push({
                text: item.name,
                ts: hotelObj.startDateTime,
                rest: item
              })
            })
          }
        } else {
          list.push({
            text: item.name,
            ts: startTime,
            rest: item
          })
        }
        if(index === (this.state.backEventObj.merchants.length - 1)){
          this.sortArr(list);
          setTimeout(() => {
            this.setState({convertedList: list, loading: false});
          }, 500);
        }
      }
    });
  }

  comp(a, b) {
    return new Date(a.ts).getTime() - new Date(b.ts).getTime();
  }

  sortArr = (list) => {
    if(list && list.length ) {
      list = list.sort(function(a,b){
        a = new Date(a.ts);
        b = new Date(b.ts);
        return new Date(a.getTime() - b.getTime());
      });
    }
  }

  createSlots = (flag) => {
    const plan = this.state.backEventObj.plan;
    delete plan.planParams;
    fetch(API_PATH + 'createSlots', {
        method: 'POST',
        body: JSON.stringify(plan),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
    .then((data) => {
        if(data && data.listOfMerchant){
          this.setState({paymentList: data.listOfMerchant, loading: false}, () => {
            localStorage.setItem('paymentList', JSON.stringify(data.listOfMerchant));
            sessionStorage.setItem('isCustomize', flag);
            this.context.router.history.push("/payment/" + data.listOfMerchant[0].service[0].slot[0].slotId);
          })
        } else {
          this.setState({loading: false});
        }
    })
    .catch(console.log)
  }

  updatePlan = (flag, nocreateSlotsFlag) => {
    this.setState({loading: true}, () => {
      const obj = this.state.backEventObj;
      if(!nocreateSlotsFlag) {
        obj.plan.is_bookable = true;
      }
      fetch(API_PATH + 'updatePlan', {
          method: 'POST',
          body: JSON.stringify(obj),
          headers: {
              'Content-Type': 'application/json'
          }
      }).then(res => res.json())
      .then((data) => {
          if(data && data.merchants){
            this.setState({backEventObj: data}, () => {
              if (!nocreateSlotsFlag) {
                this.createSlots(flag);
              } else {
                this.convertReponse();
                this.setState({loading: false});
              }
            })
          } else {
            this.setState({loading: false});
          }
      })
      .catch(console.log)
    })
  }

  openModal = (eventDetail) => {
    if (eventDetail) {
      if (eventDetail.l2Category && eventDetail.l2Category.l1category && 
        eventDetail.l2Category.l1category.category === 'Hotel') {
        const tabList = [
          {label:'Description', key: 'desc'},
          {label:'Reviews', key: 'reviews'}
        ]
        if(eventDetail.similarMerchants) {
          tabList.push({label:'Similar Hotel', key: 'similar'});
        }
        this.setState({tabList});
      }
      this.setState({eventDetail});
      this.setState({openModal: true});
    }
  }

  closeModal = () => {
    this.setState({openModal: false});
    window.scrollTo(0, 0);
  }

  getHeight(element) {
    if (!element || this.state.headerHeight) return;
    console.log(element.getBoundingClientRect().height );
    this.setState({ headerHeight: element.getBoundingClientRect().height });
  }

  onTabChange = (activeTab) => {
    const elmnt = document.getElementById(activeTab);
    if(elmnt) {
      this.setState({activeTab});
      elmnt.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        window.scrollBy(0, -this.state.headerHeight);
      }, 500);
    }
  }

  deleteEevnt = (eventObj) => {
    if(eventObj) {
      const backObj = this.state.backEventObj;
      const backIndex = backObj.merchants.findIndex((item) => {
          return item.service[0].slot[0].slotId === eventObj.service[0].slot[0].slotId;
      });
      if (backIndex !== -1) {
        this.setState({loading: true});
        backObj.merchants[backIndex]['is_deleted'] = true;
        backObj.merchants[backIndex]['is_autosuggest'] = false;
        // if (flag) {
            backObj.merchants[backIndex]['is_autosuggest'] = true;
        // }
        fetch(API_PATH + 'updatePlan', {
              method: 'POST',
              body: JSON.stringify(backObj),
              headers: {
                  'Content-Type': 'application/json'
              }
          }).then(res => res.json())
          .then((data) => {
              if(data && data.merchants){
                this.setState({ backEventObj: data }, ()=> {
                  this.convertReponse();
                })
              }else {
                this.setState({loading: false});
              }
              console.log('success');
          })
          .catch(console.log('error'))
      }
    }
  }

  openCutomizationScreen = () => {
    this.setState({showCustomizePage: true});
  }

  closeCutomizationScreen = (selectedItems) => {
    if(selectedItems && selectedItems.length) {
      const backObj = JSON.parse(JSON.stringify(this.state.backEventObj));
      backObj['merchants'] = [...this.state.backEventObj.merchants, ...selectedItems];
      this.setState({backEventObj: backObj}, ()=> {
        this.updatePlan(true, true);
      })
    }
    this.setState({showCustomizePage: false});
  }

  render() {
    const pStyle = {
        fontSize: '32px',
        textAlign: 'center',
        fontWeight: 'bold'
    };
    const loaderDiv =   <div className="yaana-loader" >
                                <Loader type="Oval" visible="true" color="#1a8cd2" height={80} width={80} />
                            </div>
    return (
      <StickyContainer className="sticky-container">
        { !this.state.showCustomizePage &&
          <section className={this.state.loading ? "my-plan h-100 p-0" : "my-plan h-100"} >
            { !this.state.stickyHeader && this.state.openModal &&
              <span onClick={ this.closeModal } style={{position: 'absolute', right:'16px', top: '8px', cursor: 'pointer'}}>X</span>
            }
            { this.state.stickyHeader && this.state.openModal &&
              <div className="sticky-header" ref={el => this.getHeight(el)}>
                <header onClick={ this.closeModal } >
                  <span>Back to main</span>
                </header>
                <div className="w-100" >
                  <Tabs tabList={this.state.tabList} onTabChange={(state) => this.onTabChange(state)}></Tabs>
                </div>
              </div>
            }
            {this.state.openModal && this.state.eventDetail &&
              <div>
                  {this.state.eventDetail ? <h2 style={{"padding":"1.2rem"}} >{this.state.eventDetail.name}</h2> : <h2>&npsp;</h2>}
                  <Eventdetail
                    plan={this.state.backEventObj}
                    eventDetail={this.state.eventDetail}
                    activeTab={this.state.activeTab}  >
                  </Eventdetail>
              </div>
            }
            {!this.state.openModal &&
              <div>
                { this.state.loading ? loaderDiv : ''}
                <div className="plan-main-box text-center mb-4">
                  <p className="m-0 pb-3 text-center mx-5" style={pStyle}>Here's Your Plan</p>
                  <Timeline
                    items={this.state.convertedList }
                    onDeleteEvent={(eventDetail) => this.deleteEevnt(eventDetail)}
                    openModal={(eventDetail) => this.openModal(eventDetail) }
                  />
                </div>
                <div className="col-12 text-center w-100 nav-bar">
                  <button className="white-text bg-blue w-100 d-block py-2 text-decoration-none mr-4" onClick={() => this.openCutomizationScreen()} >Customize</button>
                  <button className="white-text bg-warning w-100 d-block py-2 text-decoration-none ml-4" onClick={() => this.updatePlan(false)} >Get Offers</button>
                </div>
            </div>
            }
          </section>
        }
        { this.state.showCustomizePage &&
            <CustomizePlan plan={this.state.backEventObj.merchants} close={(data) => this.closeCutomizationScreen(data)} />
        }
      </StickyContainer>
    );
  }
}

export default MyPlan