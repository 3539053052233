import React, { Component } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/src/core/styles.scss';
import { Link } from 'react-router-dom';
import { API_PATH, UserInput, Vendors } from '../../Constants';
import ReactGA  from 'react-ga';

class Home extends Component {

    state = {
        popularPackages: [],
        destinationList: []
    }

    getMyPlan = () => {
        const obj = {
            stage: 'Package'
        };
        fetch(API_PATH + 'getMyPlan', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then((data) => {
            this.setState({ popularPackages: data })
        })
        .catch(console.log)
    }

    componentDidMount = () => {
        ReactGA.ga('send', 'pageview', '/home');
        // this.searchDestination();
        localStorage.clear();
    }

    componentWillUnmount = () => {
        this.setVendor();
    }

    searchDestination() {
        const obj = { inputText: '', locationType: 'destination' };
        fetch(API_PATH + 'getBestTripLocation', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then((data) => {
            this.setState({ destinationList: data })
        }).catch(console.log)
    }

    setVendor = () => {
        const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
        this.setState({vendorDetail, destinationList: vendorDetail.destinationList});
    }

    navigateEvent = (plan) => {
        UserInput.destination = plan;
        if (this.state.vendorDetail) {
            UserInput.Agent.agent_id = this.state.vendorDetail.agentId;
        }
        localStorage.setItem('userInput', JSON.stringify(UserInput));
    }

    render = () => {
        const pStyle = {
            fontSize: '32px',
            textAlign: 'center',
            fontWeight: 'bold'
        };
        return (
            <section className="h-100 mh-100">
                <article className="carousel pb-5">
                    <p className="m-0 pb-5 text-center mx-5" style={pStyle}>Where would you like to go?</p>
                    <AwesomeSlider cssModule={AwsSliderStyles}>
                        {this.state.destinationList.map((plan) => (
                            <div className="btn-container" key={plan.placeId} data-src={plan.image} >
                                <button className="btn bg-warning" onClick={this.navigateEvent.bind(this, plan) } >
                                    <b><Link to={`/date-info`}>{plan.name.split(',')[0]}</Link></b>
                                </button>
                            </div>
                        ))}
                    </AwesomeSlider>
                </article>
            </section>
        )
    }
}

export default Home