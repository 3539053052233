import React, { Component } from 'react';
import './privacy.scss';
import ReactGA from 'react-ga';

class Privacy extends Component {

    componentDidMount = () => {
        ReactGA.ga('send', 'pageview', '/privacy');
    }

    render() {
        return (
            <section className="mt-4">
                <ol>
                    <h2>DRAFT FOR DISCUSSIONS<br/>
                    (Strictly Confidential)
                    </h2>
                    <h2 className="text-center">PRIVACY POLICY</h2>
                    <p>
                        <span>Bestriptech Solution Private Limited,</span> including its subsidiaries, affiliates, associate/group
                        companies and licensors (collectively “<span>Company</span>”, “<span>we</span>”, “<span>us</span>” or “<span>our</span>”) welcomes you, irrespective of
                        whether you are a mere visitor or a member (the “<span>Customer</span>”, “<span>Subscriber</span>”, “<span>User</span>”, “<span>you</span>” or “<span>your</span>”)
                        to our Platform. We take your privacy and data protection seriously. When you browse through the
                        Platform and/or avail the Services, you agree to be bound by this privacy policy (“<span>Privacy Policy</span>”).
                        We ensure compliance with Applicable Laws by periodically updating this Privacy Policy. Hence, you
                        are strongly advised to read this policy carefully from time to time.
                    </p>
                    <li>
                        REGULATORY FRAMEWORK, SCOPE & APPLICABILITY
                        <ol>
                            <li>
                                This Privacy Policy is published in compliance with, inter alia: (i) the Information Technology
                                Act, 2000; (ii) Regulation 4 of the Information Technology (Reasonable Security Practices and
                                Procedures and Sensitive Personal Information) Rules, 2011 (<span>“Data Security Rules”</span>); and (iii)
                                Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.
                            </li>
                            <li>
                                This Privacy Policy describes your privacy rights regarding our collection, use, storage, sharing
                                and protection of your information including your personal information. This Privacy Policy
                                applies to personal information collected by us in connection with the Services. This includes
                                information collected offline through our customer engagement centres, direct marketing
                                campaigns, marketing and advertising practices and online through our Platform, mobile
                                applications, services, tools and branded pages on third party platforms and applications
                                accessed or used through such Platform or third-party platforms (<span>“Company Sites”</span>) regardless
                                of how you access or use them.
                            </li>
                            <li>
                                This Privacy Policy also applies to our targeted content, including offers and advertisements for
                                Services, which we (or a service provider acting on our behalf) may send to you on third party
                                websites, platforms and applications (“<span>Party Sites”</span>). These Third Party Sites may have
                                their own privacy policies and Terms. We encourage you to read them before using those Third
                                Party Sites.
                            </li>
                            <li>
                                This Privacy Policy is hereby incorporated into and forms part of the Terms available at [●].
                                Capitalized terms used but not defined in this Privacy Policy shall have the meaning attributed
                                to it in our Terms.
                            </li>
                            <li>
                                This Privacy Policy states the following: (i) the type of information collected from the Users,
                                including Personal Information (as defined below) and Sensitive Personal Data or Information
                                (as defined below); (ii) the purpose, means and modes of collection, usage, processing,
                                retention and destruction of such information; and (iii) how and to whom we will disclose such
                                information.
                            </li>
                            <li>
                                The information collected from you by the Company may constitute ‘personal information’ or
                                ‘sensitive personal data or information’ as defined under the Data Security Rules. <span>“Personal
                                Information”</span> is defined under the Data Security Rules to mean any information that relates to a
                                natural person, which, either directly or indirectly, in combination with other information
                                available or likely to be available to a body corporate, is capable of identifying such person. The
                                Data Security Rules further define <span>“Sensitive Personal Data or Information”</span> of a person to
                                mean such personal information about that person which consists of information relating to: (i)
                                passwords; (ii) financial information such as bank accounts, credit and debit card details or
                                other payment instrument details; (iii) physical, physiological and mental health condition; (iv)
                                sexual orientation; (v) medical records and history; (vi) biometric information; (vii) any detail
                                relating to the above clauses as provided to body corporates for providing services; and (viii)
                                any of the information received under the above clauses by body corporates for processing,
                                stored or processed under lawful contract or otherwise.
                            </li>
                            <li>
                                The Company may offer you the ability to connect with the Services using a mobile device or a
                                tablet, either through a mobile application or via a mobile optimized website. The provisions of
                                this Privacy Policy shall apply to all such mobile access and use of mobile devices.
                            </li>
                            <li>
                                The Platform and our Services are designed and intended for use by adults only. If you are a
                                minor, please do not share your personal data or information. If we discover that we have
                                collected information from a minor, we will delete that information as soon as possible. In no
                                event shall the Company be liable for any information provided by a minor. We may use your
                                personal information to carry out age verification checks and enforce any age restrictions
                                applicable to the Account.
                            </li>

                        </ol>

                    </li>
                    <li>YOUR CONSENT
                        <ol>
                            <li>You specifically accept this Privacy Policy when you access and/or use the Platform, the
                                Services and the Company Sites.</li>
                            <li>
                                By accessing, browsing and/or using the Platform, the Services and the Company Sites, you
                                are specifically consenting to the Company collecting, using, storing, sharing and disclosing
                                your Personal Information and Sensitive Personal Data or Information in accordance with this
                                Privacy Policy. If you do not agree to the collection, use, storage, sharing and disclosure of your
                                Personal Information and Sensitive Personal Data or Information in this way, please do not use
                                the Platform, the Services and the Company Sites or otherwise provide us with your Personal
                                Information and Sensitive Personal Data or Information.
                            </li>
                            <li>
                                You acknowledge that this Privacy Policy is a part of the Terms, and you unconditionally agree
                                that becoming a User of the Company Sites and the Services signifies your: (i) assent to this
                                Privacy Policy, and (ii) consent to us collecting, storing, processing and/or disclosing your
                                Personal Information and Sensitive Personal Data or Information in the manner and for the
                                purposes set out in this Privacy Policy. Your visit to the Company Sites and/or use of the
                                Services is subject to your unconditional acceptance of this Privacy Policy and the Terms.
                            </li>
                            <li>The collection, use and disclosure of information which has been designated as Personal
                                Information or Sensitive Personal Data or Information under the Data Security Rules require
                                your express consent. By affirming your assent to this Privacy Policy, you provide your consent
                                to such use, collection and disclosure in accordance with this Privacy Policy.
                            </li>
                        </ol>
                    </li>
                    <li>COLLECTION OF PERSONAL INFORMATION
                        <ol>
                            <li>When you access or use the Platform, the Services and the Company Sites, we collect
                                information about you through various means as described below:
                                <ol>
                                    <li>
                                        <span>Information Provided to the Company:</span> When you register with us (or create an
                                        Account on the Platform and use the Services), we collect information that you provide
                                        voluntarily during your registration or creation and use of your Account. This includes
                                        information such as your name, gender, the name of your company, in the event you
                                        are accessing the Platform on behalf of your organisation, your designation and
                                        capacity, your username and password, your email address, postal address, phone
                                        number and other relevant information. We may collect and store any information you
                                        provide us when you use our Services, including when you: (i) add information on a
                                        web form; (ii) add, edit or update your Account information; (iii) contact us for support
                                        or
                                        other offerings; (iv) participate in community discussions, chats, or dispute resolutions;
                                        (v) correspond with us regarding the Platform or the Services; (vi) respond to a survey
                                        conducted by us; (vii) register for a promotion provided by us; (viii) request certain
                                        features (e.g., newsletters, updates, or other products); (ix) submit feedback to us;
                                        and/or (x) submit a resume and apply for or save jobs or send invitations. The
                                        Company in this regard collects information that identifies you. We will also collect
                                        personal information about any other person which you provide when you use our
                                        Serices. You must make sure that such person has provided his consent: (i) before you
                                        provide us with his personal information; and (ii) to the collection, use, storage, sharing
                                        and disclosure of his personal information in the manner provided herein. The terms of this
                                        Policy will similarly apply to all personal information you share about any other
                                        person with us.
                                    </li>
                                    <li><span>Contacting the Company:</span> We may also collect information about you from other
                                        sources, such as through your contact with us, including our customer support and/or
                                        social media team, your interactions with other Users. Additionally, for quality and
                                        training purposes or for our own protection, we may monitor or record telephone
                                        conversations with you or anyone acting on your behalf. By communicating with us, you
                                        acknowledge that your communication may be overheard, monitored, or recorded
                                        without further notice or warning.
                                    </li>
                                    <li><span>Information Collected Automatically:</span> When you access the Platform and/or use the
                                        Services, certain information is stored in our servers automatically. The information
                                        captured automatically includes, but is not limited to, the following: data about the
                                        pages you access within the Platform, User choices and behaviour, your internet
                                        protocol (“IP”) address, device identity or unique identifier, device type, geo-location
                                        information (subject to your consent), device and connection information, mobile
                                        network information, statistics on page views, traffic to and from the sites, referral URL,
                                        ad data, and standard web log data and other information. We also collect anonymous
                                        information through our use of cookies and web beacons.</li>
                                    <li><span>Authentication and Fraud Detection:</span> In order to help protect you from fraud and
                                        misuse of your personal information, we may collect information about you and your
                                        interactions with the Platform and other Users. We may also evaluate your computer,
                                        mobile phone or other access devices to identify any malicious software or activity.
                                    </li>
                                    <li><span>Information from Other Sources/Company Social Networking Pages:</span> We may also
                                        obtain information about you, including Personal Information, from third parties and
                                        combine that with information we collect through the Platform and the Company Sites.
                                        We may collect information when you interact with our social networking pages. Any
                                        access that we may have to such information from a third party social media service is
                                        in accordance with the authorization procedures determined by that service. By
                                        authorizing us to connect with a third party service, you authorize us to access and
                                        store your name, email address(es), current city, profile picture, URL, and other
                                        personal information that the third party service makes available to us, and to use and
                                        disclose it in accordance with this Privacy Policy. The information we may receive
                                        varies by site and is controlled by that site. By authorizing us to have access to this
                                        information, you agree that we may collect, store and use this information in
                                        accordance with this Privacy Policy. You should check your privacy settings on these
                                        third-party services to understand and change the information sent to us through these
                                        services.
                                    </li>
                                    <li><span>Company Emails:</span> Emails we send you may include a technology that tells us whether
                                        you have received or opened the email or clicked a link in the email. If you do not want
                                        us to collect this information from the Company marketing emails, you can opt out of
                                        receiving the Company marketing emails. Where permissible under Applicable Law, we
                                        may also include web beacons in e-mail messages or newsletters to determine whether
                                        the message has been opened and for other analytics purposes.</li>
                                    <li><span>Company Online Advertising:</span> Company advertises online in a variety of ways,
                                        including displaying Company ads to you on other companies' websites and apps. We
                                        collect information such as which ads are displayed, which ads are clicked on, and on
                                        where the ad was displayed. We may share site usage information with trusted third
                                        parties (e.g. advertisers, advertising agencies, advertising networks, data exchanges,
                                        etc.) in order to offer you tailored content, including more relevant advertising for
                                        products and services which may be of interest to you. These third parties may set and
                                        access their own cookies, web beacons and similar tracking technologies on your
                                        device in order to help us deliver customized content and advertising to you when you
                                        use the Services or third party sites.
                                    </li>
                                    <li><span>Buttons, Tools & Content from Other Companies:</span> The Platform/Company Sites may
                                        include buttons, tools, or content that link to other companies’ services, such as
                                        Facebook, Twitter, Instagram, LinkedIn, Google Plus and Pinterest. We may collect
                                        information about your use of these features. In addition, when you see or interact with
                                        these buttons, tools, or content, or view a web page on the Platform, some information
                                        from your browser may automatically be sent to the other company, in accordance with
                                        the terms and conditions of such other company.
                                    </li>
                                    <li><span>Information from You about Others:</span> If we offer a referral option and you choose to
                                        use it to invite a friend to use the Services, we will ask you for your friend’s email
                                        address and other personal details. You must make sure your friend has consented
                                        before you provide us with your friend’s email address and personal details. We will
                                        automatically send your friend emails and other communications inviting your friend to
                                        visit the Company Sites. We store this information only to send emails and other
                                        communications to your friend and track the success of our referral program.
                                    </li>
                                    <li><span>Aggregated Data:</span> We may aggregate, anonymize and/or de-identify information
                                        collected by us so that the information no longer relates to you individually. As a result,
                                        our use and disclosure of aggregated and/or de-identified information is not restricted
                                        by this Privacy Policy, and it may be used and disclosed to others without limitation.
                                    </li>
                                    <li><span>Location Information:</span> When we provide our Services and/or other offers, we may ask
                                        you for, access, or track geo-location-based information from your mobile or other
                                        electronic device while downloading or using our mobile app or functionality. If you
                                        choose to engage with these services, we will receive certain information about your
                                        location, which may include country, state, city, or more specific location information, in
                                        order to provide you with the requested services including geographically relevant
                                        features, offers, applications, and application recommendations.
                                    </li>
                                    <li><span>Cookies and Related Tracking Technologies:</span> The Platform and/or the Company
                                        Sites use persistent and session cookies and other similar technologies to offer you a
                                        personalised experience and to remember your browsing preferences, including your
                                        log in details and choice of language. Cookies are stored primarily to ensure that in the
                                        event you return to the Platform, it shall personalise your browsing, including, enabling
                                        you to be logged on the Platform automatically. This information may, where permitted
                                        by Applicable Law, also be combined with other information we hold about you. The
                                        cookies are uniquely assigned to you and can only be accessed by your web page
                                        server in our domain. You can disable our cookies or all cookies through your browser
                                        settings. Upon the beginning of each of your visits to the Platform, the Platform also
                                        provides the choice to turn off all cookies which are not strictly necessary for the
                                        provision of Services. Please be advised that disabling cookies through either method
                                        may impact many of the Platform’s features. In addition to cookies, we and/or our third-
                                        party advertising partners may use technologies that help us better manage and
                                        analyse content.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>SECURITY
                        <ol>
                            <li>Your Personal Information and Sensitive Personal Data or Information is maintained by the
                                Company in electronic form on its equipment, and on the equipment of its employees,
                                consultants, sub-contractors, agents and representatives. Such information may also be
                                converted to physical form from time to time. The Company takes necessary precautions to
                                protect your personal information both online and off-line, and implements reasonable security
                                practices and measures including certain managerial, technical, operational and physical
                                security control measures that are commensurate with respect to the information being
                                collected and the nature of the Company’s business.
                            </li>
                            <li>The Company makes all information of a User accessible to its officers, employees,
                                consultants, agents, representatives, sub-contractors, service providers, business partners and
                                third parties only on a need-to-know basis and binds all of them to strict confidentiality
                                obligations.
                            </li>
                            <li>
                                Part of the functionality of the Company is assisting the Company’s service providers and
                                business partners to maintain and organise such information for rendering services, including
                                the Services. The Company may, therefore, retain and submit all such records and information
                                to the Company’s service providers and business partnes who request access to such
                                information.
                            </li>
                            <li>
                                In the event you opt for saving log in details, including but not limited to passwords, passport
                                number, credit card information and such other information, such Sensitive Personal Data or
                                Information shall be stored in your own devices and/or in accordance with the privacy policies of
                                the third party service providerfacilitating the log in mechanism on the Platform and the
                                Payment Gateway you opt for. In addition to the foregoing, we may, from time to time, be
                                required to share your personal information, including your sensitive personal information with
                                the Partner in connection with your purchase of the Partner Services and such other
                                transactions undertaken by you with the Partners. The Company is not, shall not and cannot be
                                responsible for the confidentiality, security or distribution of your personal information or
                                sensitive personal information by our Partners and third parties outside the scope of our
                                agreement with such Partners and third parties. Further, the Company shall not be responsible
                                for any breach of security or for any actions of any third parties or events that are beyond the
                                reasonable control of the Company including but not limited to, acts of government, computer
                                hacking, unauthorised access to computer data and storage device, computer crashes, breach
                                of security and encryption, poor quality of Internet service or telephone service of the User etc.
                            </li>
                        </ol>
                    </li>
                    <li>USAGE OF PERSONAL INFORMATION
                        <ol>
                            <li>
                                Our primary purpose in collecting personal information is to provide you with a secure, smooth,
                                efficient, and customized experience. We may use your personal information: (i) for the
                                provision of seamless and efficient Services to you, customised in accordance with User
                                preference, i.e. for performing our obligations towards you; (ii) for business management,
                                accounting and auditing purposes; (iii) to verify your identity; (iv) to enable you to access the
                                Platform and use the Services; (v) to provide you with necessary details pertaining to your
                                Account and your license to use the Services, including, sending notifications for termination of
                                contract; (vi) to accept payments from you for using the Services and/or for purchasing a
                                Partner Service, and to process and complete your transactions on the Platform and to send
                                you related information, including purchase confirmation and invoices; (vii) to send transactional
                                communications, including responses to your comments, questions and requests; (viii) to
                                contact you, through voice call, text messages, or emails, as authorised by you, under the
                                Terms; (ix) to do such activities for which you have explicitly given us consent; (x) to comply
                                with Applicable Laws, as updated from time to time; (xi) to inform you about our new products
                                and/or services; (xii) to enhance and customise our customer care services to suit your need;
                                (xiii) to respond to your queries or service requests and for resolution of the same; (xiv) to send
                                you technical notices, updates, security alerts, and support and administrative messages; (xv)
                                to advertise, promote, or market our products and services and to undertake all other types of
                                business development activities; (xvi) to comply with applicable security standards and to
                                prevent money laundering and/or fraud or any attempt thereof; (xvii) to detect, prevent or
                                remedy any breach of the Terms; (xviii) to meet our legitimate interests (or those of a third
                                party) when your interests and fundamental rights do not override those interests; (xix) to offer
                                you personalised discounts on Services and updates; (xx) to perform research, business and/or
                                operational analysis; (xxi) to protect your (or someone else’s) interest; (xxii) to deal with legal
                                disputes, if any; (xxiii) to provide, maintain or improve our products and services; (xxiv) to
                                ensure network and information security, including preventing unauthorised access to our
                                computer and electronic communications systems and preventing malicious software
                                distribution; (xxv) to conduct data analytics studies to review and better understand customer
                                satisfaction and needs; (xxvi) to generate internal reports about our User base and service
                                usage patterns, analyse the accuracy, effectiveness and popularity of the Services; (xxvii) to
                                track User activity and behaviour pattern on the Platform; and/or (xxviii) to protect the public
                                interest or for official purposes, if formally requested by any investigative or other governmental
                                authority of India. We may also use your personal information to generate charts,
                                presentations, reports, logs, or other documents about, inter alia, our User base, service usage
                                patterns, analysis of the accuracy, effectiveness and popularity of the Services for marketing, or
                                other external purposes. However, in case of such external use, we shall ensure to anonymise
                                the personal information provided by you. You hereby give your express consent to us for using
                                your personal information for this purpose.
                            </li>
                            <li>
                                We may contact you as necessary to enforce or change our policiesor any agreement we may
                                have with you. When contacting you via phone, to reach you as efficiently as possible, we may
                                use and you consent to receive, auto-dialled or pre-recorded calls and text messages. Where
                                applicable and permitted by Law, you may decline to receive certain communications.
                            </li>
                        </ol>
                    </li>
                    <li>DATA RETENTION
                        <ol>
                            <li>We implement reasonable security practices to store your data accurately, confidentially and
                                securely. The term for which we shall be retaining your personal information depends solely on
                                the nature of the information. We shall retain your personal information only for such period of
                                time as is necessary to fulfil the purposes we collected it for, including for the purposes of
                                satisfying any legal, accounting, or reporting requirements.
                            </li>
                            <li>
                                The Company may, at any time, delete your information, including but not limited to, your
                                personal information, without any prior notice to you. Upon the expiry of your license to use the
                                Services or deactivation and/or termination of your Account, the Company shall have the right
                                to, immediately and permanently, delete all stored messages, emails, contents and all other
                                records of your Account without any notice.
                            </li>
                            <li>
                                Except to the extent provided herein, we will destroy your personal information upon the
                                fulfilment of its purpose. We may, however, retain your personal information for: (i) accounting
                                purposes for such time period as is prescribed under Applicable Law; and (ii) for legal purposes
                                till the completion of statutory limitation for bringing any suit, if any.
                            </li>
                            <li>
                                Under certain circumstances, we alter the personal information collected from Users to render it
                                anonymous so that, it could no longer be personally identifiable. In the event of such
                                anonymisation of data, we shall have the right to retain such data for as long as we require and
                                use such data without intimating you of such use and for any lawful purposes of whatsoever
                                nature, whether mentioned in this Privacy Policy or not.
                            </li>
                            <li>
                                Retention of your personal information, including your sensitive personal information by a third
                                party, or another User shall be governed by their privacy policies and the Company is neither
                                responsible nor could be held liable for any breach or non-compliance from such party’s part.
                            </li>
                        </ol>
                    </li>
                    <li>SHARING OF INFORMATION COLLECTED
                        <ol>
                            <li><span>Processing of Transactions:</span> We share information, including personal information, with our
                                officers, employees, consultants, agents, representatives, sub-contractors, service providers
                                and business partners to enable them to undertake, perform and deliver the services purchased
                                by you.
                            </li>
                            <li><span>Third Party Service Providers:</span> We share information, including personal information, with our
                                third-party service providers that we use to: (i) to host and maintain the Platform, (ii) for
                                application development, back-up, storage, payment processing, analytics, marketing and front
                                or back end development and support; and (iii) for audits, web analysis and other services
                                required to enable seamless provision of Services. These third-party service providers may
                                have access to or process your personal information for the purpose of providing these services
                                for us. We do not permit our third-party service providers to use the personal information that
                                we share with them for their marketing purposes or for any other purpose than in connection with
                                the services they provide to us. We forbid the third party service providers from using your
                                personal data for their marketing purposes or for any purposes other than for the provision of
                                services to us. Please note that third party service providers have their own privacy policies,
                                and the Company is not responsible for their actions, including their information protection
                                practices.
                            </li>
                            <li><span>Sharing with the Partners:</span> We may, from time to time, be required to share your personal
                                information, including your personal information and sensitive personal information with the
                                Partners in connection with your purchase of the Partner Services and such other transactions
                                undertaken by you with such Partners. In such cases, protection of your personal information
                                by the Partner shall be governed by the privacy policy of the Seller. You are hereby advised to
                                be aware and carefully read and understand the privacy policy of the Partner.
                            </li>
                            <li><span>Compliance with Laws and Law Enforcement Requests & Protection of our Rights:</span> We
                                may also share your personal information and data with third parties where we believe in good
                                faith that such disclosure is necessary to comply with any Applicable Law or to comply with
                                legal processes and to respond to requests from public and Government authorities, including
                                public and government authorities outside your country of residence, to prevent imminent
                                physical harm or financial loss, to enforce our Terms, including to meet national security or law
                                enforcement requirements. We may disclose personal information to respond to subpoenas,
                                court orders, or legal process, or to establish or exercise our legal rights or defend against
                                legal
                                claims. We may also share such information if we believe it is necessary in order to investigate,
                                prevent, or take action regarding illegal activities, suspected fraud, situations involving
                                potential
                                threats to the physical safety of any Person, violations of our Terms, or as otherwise required by
                                Law. We may also share your information in situations involving potential threats to the physical
                                safety of any Person, violations of this Privacy Policy or to respond to the claims of violation of
                                the rights of third parties and/or to protect the rights, property and safety of the Company, our
                                employees, Users, Partners or the public and obtaining your consent is difficult. We, at our sole
                                discretion, shall have the right to disclose your personal information to third parties, in the
                                event
                                we believe it to be necessary or appropriate in connection with an investigation of fraud,
                                intellectual property infringement, piracy, or any other unlawful activity.
                            </li>
                            <li><span>Business Transaction:</span> We may share your information in connection with any corporate
                                restructuring, including a merger, demerger, amalgamation, reorganisation, sale of some or all
                                of the Company’s assets, a change in control transaction or a financing or acquisition of all or a
                                portion of our business by another company.
                            </li>
                            <li><span>Consent:</span> We may share your information in any other circumstances where we have your
                                express consent.
                            </li>
                            <li> <span>Company Affiliates:</span> We may share your information among the affiliates of Company so that
                                we can better provide better services that are relevant to you as well as generally to provide the
                                services as applicable.
                            </li>
                            <li><span>Publishers and Advertisers:</span> We may share the information with our publishers and
                                advertisers to aid in their understanding of how Users are using our apps and engaging with
                                ads.
                            </li>

                        </ol>
                    </li>
                    <li>DATA PROTECTION
                        <ol>
                            <li>
                                All our third-party service providers are required to take appropriate security measures to
                                protect your personal information in line with our policies. We do not allow our third-party
                                service providers to use your personal information for their own purposes. We only permit them
                                to process your personal information for specified purposes and in accordance with our
                                instructions. Any processing of your personal information by third party shall only be done by
                                treating the information collected from you confidentially, legally and securely.
                            </li>
                            <li>
                                We have put in place appropriate security measures to prevent your personal information from
                                being accidentally lost, used or accessed in an unauthorised manner. In addition, we have
                                limited the access to your personal information to those who have a need to know.
                            </li>
                            <li>
                                It is necessary for the Users to keep their personal information and their password confidential.
                                The Users must not share their usernames and passwords with anyone to prevent
                                unauthorised use of their personal information. You shall, at all times, be responsible for the
                                protection of and the disclosure of your username and password. We shall, in no way, be liable
                                for disclosure of your username and/or password, or your personal information, in the event of
                                any unauthorised access of your Account. In the event of such unauthorised usage, you shall
                                notify us immediately.
                            </li>
                            <li>
                                We have put in place procedures to deal with any suspected data security breach and will notify
                                you and any applicable regulator of a suspected breach where we are legally required to do so.
                            </li>

                        </ol>
                    </li>
                    <li>ACCESSING AND DELETING YOUR INFORMATION & OPTING OUT
                        <ol>
                            <li>
                                You may, under Applicable Law, have the right to access, correct, update or delete the personal
                                information the Company holds about you. In order to exercise your rights, to the extent
                                permitted and required of the Company under Applicable Law, please contact us at [●].
                            </li>
                            <li>
                                We respect your communication preferences, if you no longer wish to receive notifications via
                                our Platform, you can adjust your preferences. If you prefer not to receive ads that are tailored
                                to your interests, you can opt out by writing to us at [●] at any time.
                            </li>
                            <li>
                                The Company may use your information to contact you in the future to inform you about the
                                services the Company believes will be of interest to you. If the Company does so, each
                                communication it sends to you will contain instructions permitting you to "opt-out" of receiving
                                future communications. In addition, if at any time you wish not to receive any future
                                communications or wish to have your name deleted from our mailing lists, please contact us at
                                [●].
                            </li>
                            <li>
                                Due to the nature of the Services, and the restricted access to our Platform, the only way for
                                you to opt out of sharing your personal information with us which are essential for providing the
                                Services is by deactivating your Account. Upon deletion of your Account, the Company shall, at
                                its earliest, stop sharing your personal information in accordance with this Privacy Policy. Any
                                personal information shared before such deactivation shall be governed by this Privacy Policy.
                                After a period of time, your data may be anonymized and aggregated, and then may be held by
                                us as long as necessary for us to provide our Services effectively, but our use of the
                                anonymized data will be solely for analytic purposes. Please note that your withdrawal of
                                consent or cancellation of Account may result in the Company being unable to provide you with
                                its Services or to terminate any existing relationship the Company may have with you.
                            </li>
                            <li>
                                If you wish to opt-out of receiving non-essential communications such as promotional and
                                marketing-related information regarding the Services, please send us an email at [●].
                            </li>
                        </ol>
                    </li>
                    <li>COMMUNITY FORUMS
                        <ol>
                            <li>The Platform may offer publicly accessible blogs, community forums, comments sections,
                                discussion forums, or other interactive features (“Interactive Areas”). You should be aware that
                                any information that you post in the Interactive Areas might be read, collected, and used by
                                others who access it. To request removal of your personal information from an Interactive Area,
                                contact us at [●]. In some cases, we may not be able to remove your personal information, in
                                which case we will let you know if we are unable to do so and why.
                            </li>
                        </ol>
                    </li>
                    <li>LINKS TO THIRD PARTY SITES AND SERVICES
                        <ol>
                            <li>
                                The Platform may contain links to third-party websites, including but not limited to, a Partner’s
                                website, an aggregator’s website, payment gateway, e-commerce websites, marketing platforms and
                                social media services. Your use of these features may result in the collection,
                                processing, use, storage or sharing of information about you, including your personal
                                information, by these websites, depending on the feature. Please be aware that we are not
                                responsible for the content or privacy practices of other websites or services that may be linked
                                on our Platform or Services. We do not endorse or make any representations about third-party
                                websites or services. The information you choose to provide to or that is collected by these third
                                parties is not covered by our Privacy Policy. We shall, at no time, be liable for any unauthorised
                                use of your personal information by these third-party websites. We strongly encourage you to
                                read such third parties’ privacy policies.
                            </li>
                        </ol>
                    </li>
                    <li>CHANGES TO PRIVACY POLICY
                        <ol>
                            <li>
                                We reserve the right to update this Privacy Policy at any time, and from time to time, with or
                                without advance notice. In the event there are significant changes in the way we treat User’s
                                personal information, or in the Privacy Policy itself, we will display a notice on the Platform or
                                send Users an email, so that you may review the changed terms prior to continuing to use the
                                Platform and the Company Sites. Please check this page periodically for changes. Use of
                                information we collect is subject to the Privacy Policy in effect at the time such information is
                                used. Please review the changes carefully. Your continued access to the Platform or the
                                Company Sites or use of our Services following the posting of changes to this Privacy Policy
                                will mean you consent to and accept those changes. As always, if you object to any of the
                                changes to our terms, and you no longer wish to use the Services, you may stop browsing
                                through the Platform, deactivate your Account and/or contact us at [●].
                            </li>
                            <li>
                                If a User uses the Services or accesses the Platform and the Company Sites after a notice of
                                change has been sent to such User or published on the Platform or the Company Sites, such
                                User hereby provides his/her/its consent to the changed terms.
                            </li>
                        </ol>
                    </li>
                    <li>CHILDREN'S AND MINOR'S PRIVACY
                        <ol>
                            <li>
                                The Platform and our Services are designed and intended for use by adults. If we discover that
                                we have collected information from a minor, we will delete that information as soon as possible.
                                We also advise you strongly to not to save any of minor’s information on the Platform or the
                                Company Sites, unless such information does not constitute sensitive personal information,
                                and/or is absolutely essential to be stored.
                            </li>
                            <li>
                                We strongly encourage parents and guardians to supervise the online activities of their minors
                                to help provide a child-friendly online environment and prevent minors from disclosing their
                                name, address, and other personally identifiable information online without parental permission.
                                Although the Services are not intended for use by minors, we respect the privacy of minors who
                                may inadvertently use the internet or the mobile application.
                            </li>
                        </ol>
                    </li>
                    <li>DISCLAIMER
                        <ol>
                            <li>We shall not be liable for disclosure of your username and password and you shall be solely
                                responsible for protecting your username and password, at all times. You shall choose your
                                password carefully and shall not use other User’s username and password to log in to the
                                Platform at any point of time. You shall be solely liable for any unauthorised use of the Platform
                                through your Account. In the event of such unauthorised usage, you shall notify us immediately
                                and reset your password accordingly.
                            </li>
                            <li>
                                While we give utmost importance to protecting User data, we cannot ensure that all your
                                personal information (including sensitive information information) will never be disclosed in
                                ways not otherwise described in this Privacy Policy. Therefore, although we are committed to
                                protecting your privacy, we do not promise, and you should not expect, that your personal
                                information will always remain private. As a User of the Platform, you understand and agree that
                                you assume all responsibility and risk for your use of the Platform, the internet generally,
                                and the documents you post or access and for your conduct on and off the Platform.
                            </li>
                        </ol>
                    </li>
                    <li>MISCELLANEOUS
                        <ol>
                            <li>
                                Address for Privacy Queries: Should you have questions about this Privacy Policy or our
                                information collection, use and disclosure practices, you may contact the Grievance Officer
                                appointed by the Company in accordance with the provisions of the Data Security Rules. We
                                will use reasonable efforts to respond promptly to any requests, questions or concerns, which
                                you may have regarding our use of your personal information. If you have any grievance with
                                respect to our use of your information, you may communicate such grievance to the Grievance
                                Officer mentioned below:
                                <br>
                                </br>
                                <p>Name: [●]</p>
                                <p>Registered Address: [●]</p>
                                <p>Email: [●]</p>
                                <p>Contact Number: [●]</p>
                            </li>
                            <li>
                                The general provisions as outlined in the Terms shall be applicable to this Privacy Policy as
                                well.
                            </li>
                        </ol>
                    </li>
                </ol>
            </section>
        )
    }

}
export default Privacy