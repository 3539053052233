import React, { Component } from 'react';
import { API_PATH, BookingInput } from '../Constants';
import PropTypes from "prop-types";
import Loader from 'react-loader-spinner';
import ReactGA from 'react-ga';
import 'antd/dist/antd.css';
import { Form, Input, Button } from 'antd';
import { Vendors } from '../Constants';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const validateMessages = {
  required: 'This field is required!',
  types: {
    email: 'Not a validate email!',
    number: 'Not a validate number!',
  },
  number: {
    range: 'Must be between ${min} and ${max}',
  },
};

class Payments extends Component {

    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            paymentList: [],
            paymentModel: null,
            pnrNumber: undefined,
            email_id: undefined,
            mobile_no: undefined,
            name: undefined,
            validate: {
                phoneInvalid: false,
                emailInvalid: false,
            },
            isCustomize: false,
            loading: false,
            vendorDetail: null
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMobileChange = this.handleMobileChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
    }

    componentWillMount = () => {
        this.setVendor();
        const isCustomize = JSON.parse(sessionStorage.getItem('isCustomize'));
        this.setState({isCustomize}, ()=> {
            console.log(this.state.isCustomize);
        });
    }

    componentDidMount() {
        ReactGA.ga('send', 'pageview', '/payment');
        const paymentList = JSON.parse(localStorage.getItem('paymentList'));
        if(paymentList && paymentList.length) {
            this.setState({paymentList});
        } else {
            this.context.router.history.push("/my-plans");
        }
        const { match: { params } } = this.props; 
        this.setState({pnrNumber: params.pnrNumber});
    }
  
    _onSelect (e) {
      console.log(e);
    }

    setVendor = () => {
        const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
        this.setState({vendorDetail, destinationList: vendorDetail.destinationList});
    }

    createSingleBooking = (values) => {
        if(this.state.pnrNumber && values.email_id && values.mobile_no && values.name) {
            this.setState({loading: true});
            BookingInput[0].slot_pnr_no = this.state.pnrNumber;
            BookingInput[0].bookingInput.email_id = values.email_id;
            BookingInput[0].bookingInput.mobile_no = values.mobile_no;
            BookingInput[0].bookingInput.Passengers[0].first_name = values.name;
            fetch(API_PATH + 'createBooking', {
                method: 'POST',
                body: JSON.stringify(BookingInput),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
            .then((data) => {
                if(data && data.length){
                    this.setState({loading: false});
                    this.context.router.history.push("/confirmation");
                } else {
                    this.setState({loading: false});
                }
            })
            .catch(console.log)
        } 
    }

    createSlots = (e) => {
        const obj = this.state.backEventObj.plan;
        delete obj.planParams;
        e.preventDefault();
        fetch(API_PATH + 'createBooking', {
            method: 'POST',
            body: JSON.stringify(this.state.backEventObj.plan),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then((data) => {
            if(data && data.listOfMerchant){
              this.context.router.history.push("/payment");
            }
        })
        .catch(console.log)
    }

    makePayment = () => {
        let reqCount = 0;
        let respCount = 0;
        this.state.paymentList.forEach((element, index) => {
            if (element.l2Category.l1category.category === 'Hotel' ||
                element.l2Category.l1category.category === 'Flight' ||
                element.l2Category.l1category.category === 'Cab') {
                // this.state.paymentModel.slot_pnr_no = element.service[0].slot[0].slotId;
                reqCount ++;
                fetch(API_PATH + 'createBooking', {
                    method: 'POST',
                    body: JSON.stringify([this.state.paymentModel]),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json())
                .then((res) => {
                    respCount ++;
                    if (respCount === reqCount) {
                        this.activeIndex++;
                    }
                    if (res && res.length) {
                        // this.setState({paymentList[index]['bookingReference']: res[0].BookingReference.booking_Ref_No })
                    }
                })
                .catch(() => {
                    respCount ++;
                    if (respCount === reqCount) {
                        this.activeIndex++;
                    }
                })
            }
        });
    }

    handleNameChange = (event) => {
        this.setState({name: event.target.value});
    }

    handleEmailChange = (event) => {
        this.setState({email_id: event.target.value});
    }

    handleMobileChange = (event) => {
        this.setState({mobile_no: event.target.value});
    }

    render() {
        const pStyle = {
            fontSize: '32px',
            textAlign: 'center',
            fontWeight: 'bold'
        };
        const subheadingStyle = {
            fontSize: '14px' 
        }
        const loaderDiv = <div className="yaana-loader" >
            <Loader type="Oval" visible="true" color="#1a8cd2" height={80} width={80} />
        </div>
        const pDive = <p className="py-2">Desktop view is preferable for a better edit experience. We are working hard to get edit experience on mobile devices. Until then, we can customise the plan for you and send you an updated plan.</p>
        return (
            <div>
                <section className="text-center">
                    { this.state.loading ? loaderDiv : ''}
                    { this.state.vendorDetail &&
                        <article className="pb-5">
                            <p className="m-0 text-center mx-5" style={pStyle}>{ this.state.vendorDetail.paymentPageConfig.ContactDetails.header } </p>
                            <p className="m-0 text-center mx-5 subheading" style={subheadingStyle}> { this.state.vendorDetail.paymentPageConfig.ContactDetails.header } </p>
                            { this.state.isCustomize ? pDive : '' }
                        </article>
                    }
                    <Form {...layout} name="nest-messages" 
                        className="px-3"
                        onFinish={(values) => this.createSingleBooking(values)} validateMessages={validateMessages} size='large'>
                        <Form.Item
                            name={'name'}
                            label="Name"
                            rules={[
                            {
                                required: true,
                                min:6
                            },
                            ]}
                        >
                            <Input 
                                placeholder="Your Name"
                            />
                        </Form.Item>
                        <Form.Item
                            name={'email_id'}
                            label="Email"
                            rules={[
                            {
                                required: true,
                                type: 'email',
                            },
                            ]}
                        >
                            <Input 
                                placeholder="Your Email address"
                            />
                        </Form.Item>
                        
                        <Form.Item
                            name={'mobile_no'}
                            label="Phone Number"
                            rules={[
                            {
                                required: true,
                                len: 10
                            },
                            ]}
                        >
                            <Input 
                                placeholder="Your Phone Number"
                            />
                        </Form.Item>
                    
                        <article className="row m-0 nav-bar">
                            <div className="col-9 text-center">
                                <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                                    <Button htmlType="submit" className="white-text bg-warning w-100 d-block py-2 my-2 text-decoration-none"> { this.state.vendorDetail ? this.state.vendorDetail.paymentPageConfig.submitLabel : 'Submit' } </Button>
                                </Form.Item>
                            </div>
                        </article>
                    </Form>
                </section>
                { this.state.loading &&
                    <div className="yaana-loader" >
                        <Loader type="Oval" visible="true" color="#1a8cd2" height={80} width={80} />
                    </div>
                }
            </div>
        )
    }
}
export default Payments