import React, { Component } from 'react';
import ReactGA from 'react-ga';

class Confirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
          options: ['Family', 'Friends'],
          defaultOption: 'Family',
          planId: ''
        };
    }

    componentDidMount = () => {
        ReactGA.ga('send', 'pageview', '/confirmation');
    }

    render() {
        return (
            <section className="text-center d-flex align-items-center h-100">
                <article className="pb-5">
                    <span className="d-block py-2">Thank You. We have e-mailed your plan!</span>
                    <span className="d-block py-2"></span>
                    <span className="d-block py-2">If you have any questions, please shoot an email to <b><a href="mailto:team@goyaana.com"> team@goyaana.com</a></b>.</span>
                </article>
            </section>
        )
    }
}
export default Confirmation