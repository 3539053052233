import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const showCrossList = ['Attraction', 'Hotel'];
const showModalList = ['Attraction', 'Hotel', 'Restaurant', 'Flight'];

/**
 * @usage
 * <TimlineItem time={time} text={text} />
 */
function TimlineItem(props) {
  const {text, time, rest} = props;
  return (
    <li>
      <i className="fa" />
      <div className="time-line-item">
        {
          (rest && rest.l2Category && rest.l2Category.l1category && showCrossList.includes(rest.l2Category.l1category.category))  ? <i className="close-event" onClick={() => props.deleteEvent(rest)} ><FontAwesomeIcon icon="times" size="xs" /></i> : ''
        }
        <span className="time">
          <i className="fa fa-clock-o" /> {time}
        </span>
        {
          showModalList.includes(rest.l2Category.l1category.category) ?
          <div className="time-line-header" onClick={(text) => props.openModal(text)} >{text}</div> :
          <div className="time-line-header">{text}</div>
        }
      </div>
    </li>
  );
}

TimlineItem.defaultProps = {};

TimlineItem.propTypes = {
  time: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TimlineItem;
