import React, { Component } from 'react';
import './tabbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Vendors } from '../../Constants';

class TabBarExample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabList: [],
      activeTab: null,
      vendorDetail: null
    };
  }

  componentWillMount = () => {
    this.setVendor();
  }

  setVendor = () => {
    const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
    this.setState({vendorDetail, tabList: vendorDetail.tabList, activeTab: vendorDetail.tabList[0]});
  }

  handleChange = (activeTab) => {
    this.setState({activeTab});
    this.props.onTabChange(activeTab);
  }

  render() {
    return (
      <div className="row m-0 tab-bar">
        { this.state.tabList && this.state.tabList.length &&
          this.state.tabList.map((tab, index) =>
            <div key={index} onClick={() => this.handleChange(tab) } className="col-3 col-md-4 px-1 mx-2" >
              <div className={`tab-item d-flex flex-column align-items-center ${(this.state.activeTab.key === tab.key) ? 'active' : ''}`}>
                <span className="icon-container">
                  <FontAwesomeIcon icon={tab.icon ? tab.icon : 'hotel'} />
                </span>
                <span>{tab.label} </span>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default TabBarExample
