import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Vendors } from '../../Constants';
import YouTube from 'react-youtube';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './mahe.scss';

class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {
          defaultOption: 'Family',
          destinationList: [],
          vendorDetail: null
        };
    }

    componentWillMount = () => {
        this.setVendor();
    }

    componentDidMount() {
        ReactGA.ga('send', 'pageview', '/');
    }

    setVendor = () => {
        const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
        this.setState({vendorDetail, destinationList: vendorDetail.destinationList});
    }

    render() {
        const pStyle = {
            fontSize: '30px',
            textAlign: 'center',
            fontWeight: 'bold',
        };
        const opts = {
            height: '260'
        }
        return (
            <section className="text-center">
                { this.state.vendorDetail && this.state.vendorDetail.landingPage &&
                    <div>
                        { this.state.vendorDetail.landingPage.carousel && this.state.vendorDetail.landingPage.carousel.length &&
                            <article className="mb-2">
                                <Carousel>
                                    {this.state.vendorDetail.landingPage.carousel.map((slide, index) =>
                                        <div key={index} className="bg-slide">
                                            <div className="exp_inner">
                                                <figure>
                                                    <img alt='' src={ slide.img } ></img>
                                                </figure>
                                                <figcaption>
                                                    <h3 className="text-center">{ slide.label }</h3>
                                                    <span>{ slide.subLabel }</span>
                                                </figcaption>
                                                {/* <a></a> */}
                                            </div>
                                        </div>
                                    )}
                                </Carousel>
                            </article>
                        }
                        {/* { this.state.vendorDetail.landingPage.experiance && this.state.vendorDetail.landingPage.experiance.length &&
                            <article class="row m-0 mb-4 grid-box">
                            {this.state.vendorDetail.landingPage.experiance.map((slide, index) =>
                                <div key={index} className="col-6 p-0 px-2 m-0 mb-2">
                                    <div className="exp_inner">
                                        <figure>
                                            <img alt="" src={ slide.img } ></img>
                                        </figure>
                                        <figcaption>
                                            <h3 className="m-0 text-center">{ slide.label }</h3>
                                            <span className="m-0">{ slide.subLabel }</span>
                                        </figcaption>
                                        <a></a>
                                    </div>
                                </div>
                            )}
                        </article>
                        } */}
                        { this.state.vendorDetail.landingPage.howItWorks.active &&
                            <article className="mb-2">
                                <p className="m-0 text-center mx-4 mb-2" style={pStyle}>{ this.state.vendorDetail.landingPage.howItWorks.heading }</p>
                                <img className="w-100" src={ this.state.vendorDetail.landingPage.howItWorks.url } alt="logo" ></img>
                            </article>
                        }
                        { this.state.vendorDetail.landingPage.quickDemo.active &&
                            <article className="mb-2">
                                <div className="pb-5 justify-content-center">
                                    <p className="m-0 pb-3 text-center mx-5" style={pStyle}>{ this.state.vendorDetail.landingPage.quickDemo.heading }</p>
                                    <YouTube videoId={this.state.vendorDetail.landingPage.quickDemo.url} opts={opts} onReady={this._onReady}/>
                                </div>
                            </article>
                        }
                        <div className="col-12 text-center nav-bar">
                            <Link to={`/date-info`} className="white-text bg-warning font-weight-bold col-6 d-block py-2 text-decoration-none" >Get Started</Link>
                        </div>
                    </div>
                }
            </section>
        )
    }
}
export default Landing