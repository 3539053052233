import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import TimlineItem from './TimlineItem';

/**
 * Converts array of events in to object having date as the key and list of 
 * event for that date as the value
 *
 * @param {Array} items Array of events in the form of ts and text
 * @returns {Object} return object with key as date and values array in events for that date
 */
function getFormattedData(items) {
  const activities = {};
  items.forEach(({ts, text, rest}, index) => {
    const date = moment(ts);
    const dateStr = date.format('DD MMM YYYY');
    const list = activities[dateStr] || [];
    list.push({
      time: date.format('hh:mm a'),
      text,
      key: index,
      rest
    });
    activities[dateStr] = list;
  });
  return activities;
}

function Timeline(props) {
  const {items} = props;
  const activities = getFormattedData(items);
  const dates = Object.keys(activities);

  const openModal = (rest) => {
    props.openModal(rest);
  }
  
  const deleteEvent = (rest) => {
    props.onDeleteEvent(rest);
  }

  return (
    <div className="time-line-ctnr">
      {dates.map(d =>
        <ul className="time-line" key={d}>
          <li className="time-label">
            <span>
              {d}
            </span>
          </li>
          {activities[d].map(({time, text, key, rest}) =>
            <TimlineItem time={time} text={text} key={key} rest={rest} openModal={()=> openModal(rest)} deleteEvent={(rest) => deleteEvent(rest)} />,
          )}
        </ul>,
      )}
    </div>
  );
}

Timeline.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    ts: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

export default Timeline;
