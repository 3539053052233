import React, { Component } from 'react';
import CheeseburgerMenu from "cheeseburger-menu";
import HamburgerMenu from "react-hamburger-menu";
import MenuContent from "./menuContent";
import './header.scss';
import logo from '../../assets/images/logo.png';
import PropTypes from "prop-types";
import { Vendors } from '../../Constants';
class Header extends Component {

    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            menuOpen: false,
            vendorDetail: null
        };
    }

    componentWillMount = () => {
        this.setVendor();
    }

    openMenu() {
        this.setState({ menuOpen: true });
    }

    closeMenu() {
        this.setState({ menuOpen: false });
    }

    navigateToHome = () => {
        if(this.state.vendorDetail.logoExtLink) {
            window.open(this.state.vendorDetail.logoExtLink, '_self')
        } else if(this.state.vendorDetail.logoRouteLink) {
            this.context.router.history.push(this.state.vendorDetail.logoRouteLink);
        } else {
            this.context.router.history.push('/');
        }
    }

    setVendor = () => {
        const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
        this.setState({vendorDetail});
    }

    render() {
        return (
            <header>
                <article>
                    <img src={ this.state.vendorDetail ? this.state.vendorDetail.logo : logo } onClick={this.navigateToHome} alt="logo" ></img>
                </article>
                { this.state.vendorDetail &&
                    <div className="menu-link" >
                        <CheeseburgerMenu isOpen={this.state.menuOpen} closeCallback={this.closeMenu.bind(this)}>
                            <MenuContent closeCallback={this.closeMenu.bind(this)} />
                        </CheeseburgerMenu>
                        <HamburgerMenu
                            isOpen={this.state.menuOpen}
                            menuClicked={this.openMenu.bind(this)}
                            width={32}
                            height={24}
                            strokeWidth={3}
                            rotate={0}
                            color="rgb(72, 133, 237)"
                            borderRadius={0}
                            animationDuration={0.5}
                        />
                    </div>
                }
            </header>
        )
    }

}
export default Header