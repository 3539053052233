import React from 'react';
import './customizeplan.scss';
import TabBarExample from './../tabbar/tabbarexample.js';
import { API_PATH, Vendors } from '../../Constants';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CustomizePlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      loading: true,
      activeTab: null,
      userInput: null,
      vendorDetail: null,
      size: 'xs'
    };
  }

  componentWillMount = () => {
    this.setVendor();
  }

  setVendor = () => {
    this.vendorId = window.location.host;
    const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
    this.setState({vendorDetail, activeTab: vendorDetail.tabList[0]});
  }

  componentDidMount() {
    let uInput = localStorage.getItem('userInput');
    uInput = uInput ? JSON.parse(uInput) : null;
    if(uInput) {
      this.setState({dataSource: [], userInput: uInput}, () => {
        this.getAvailabilty();
      })
    }
  }

  getAvailabilty = () => {
    const req = {
      UserInput: this.state.userInput,
      L1Category: {category: this.state.activeTab.key}
    }
    fetch(API_PATH + 'getAvailablity', {
        method: 'POST',
        body: JSON.stringify(req),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())
    .then((dataSource) => {
        if(dataSource && dataSource.length){
          this.setState({ dataSource, loading: false });
          if(this.props && this.props.plan && this.props.plan.length) {
            dataSource.map((eachPlan) => {
              const exist = this.props.plan.find((availableObj) => {
                return availableObj.merchant_id === eachPlan.merchant_id;
              })
              if(exist) {
                eachPlan['is_selected'] = true;
              }
              return eachPlan;
            })
            setTimeout(() => {
              this.setState({ dataSource, loading: false });
            },200)
          }
        } else {
          this.setState({ loading: false });
        }
    })
    .catch(console.log)
  }

  onTabChange = (activeTab) => {
    this.setState({dataSource: [], activeTab, loading: true}, ()=> {
      this.getAvailabilty();
    });
  }

  unSelectAllCards = () => {
    if(this.state.dataSource && this.state.dataSource.length) {
      this.state.dataSource.map((item) => {
        item.is_selected = false;
      })
      return this.state.dataSource;
    }
  }

  selectCard = (index) => {
    if(this.state.activeTab === 'Hotel') {
      const dataSource1 = this.unSelectAllCards();
      this.setState({dataSource: dataSource1});
    }
    setTimeout(() => {
      const dataSource = this.state.dataSource;
      dataSource[index]['is_selected'] = !dataSource[index].is_selected;
      dataSource[index]['is_deleted'] = false;
      dataSource[index]['is_autosuggest'] = true;
      this.setState({dataSource});
      console.log(dataSource, dataSource[index], index);
    }, 200)
  }

  close = (flag) => {
    if(flag) {
      const list = this.state.dataSource.filter((item) => {
        if(item.is_selected) {
          return item;
        }
      })
      this.props.close(list);
    } else {
      this.props.close();
    }
  }

  render() {
    return (
        <div className="h-100">
          <div className="customize-main">
              <TabBarExample zIndex={100000} onTabChange={(state) => this.onTabChange(state)} />
              <div className="card-details">
                { this.state.dataSource &&
                  this.state.dataSource.map((data, index) =>
                    <div key={index} className={`card ${data.is_selected ? "active" : ""}`} onClick={() => this.selectCard(index)} >
                        <section className="left-card">
                          <div className="hotel-img">
                            { data && data.photos &&
                              <img alt="" src={data.photos.thumbPhotoURL} />
                            }
                          </div>
                          <div className="hotel-info">
                            <p>{data.name}</p>
                            { data.tags &&
                              <p>{data.tags}</p>
                            }
                            <div className="ratting ng-star-inserted">
                              <FontAwesomeIcon color="#FF9529" icon="star" size="sm" />
                              <FontAwesomeIcon color="#FF9529" icon="star" size="sm" />
                              <FontAwesomeIcon color="#FF9529" icon="star" size="sm" />
                              <FontAwesomeIcon color="#FF9529" icon="star" size="sm" />
                              <FontAwesomeIcon color="#FF9529" icon="star" size="sm" />
                            </div>
                          </div>
                          </section>
                        { data.merchant_description &&
                          <section className="right-card">
                            {data.merchant_description.length > 80 ? data.merchant_description.substring(0, 80) + '...' : data.merchant_description.substring(0, 80)  }
                          </section>
                        }
                        <div className="d-flex justify-content-end">
                            <span className={`mr-2 ${data.is_selected ? "d-none" : "d-block"}`}>
                              <FontAwesomeIcon color="#1a8cd2" icon="plus-circle" size="lg" />
                            </span>
                          <span className={`mr-2 ${!data.is_selected ? "d-none" : ""}`}>
                            <FontAwesomeIcon color="green" icon="check-circle" size="lg" />
                          </span>
                        </div>
                    </div>
                  )
                }
              </div>
          </div>
          <div className="col-12 text-center w-100 nav-bar">
                <div className="col-4">
                    <span onClick={() => this.close(false)} >Cancel</span>
                </div>
              <button className="white-text bg-warning w-100 d-block py-2 text-decoration-none" onClick={() => this.close(true)} >Update Plan</button>
          </div>
          { this.state.loading &&
            <div className="yaana-loader" >
                <Loader type="Oval" visible="true" color="#1a8cd2" height={80} width={80} />
            </div>
          }
        </div>
    );
  }
}

export default CustomizePlan;