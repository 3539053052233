
import React, { Component } from 'react';
import './search-modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_PATH, Vendors } from '../../Constants';

class SearchModal extends Component {

    constructor(props){
        super(props);
        this.state = {
            destinationList: [],
            searching: false,
            searchText: '',
            vendorDetail: null
        }
    }

    searchDestination(evt){
        var searchText = evt.target.value;
        if(this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.setState({searchText});
            this.searchDestinationApi(searchText);
        }, 500);
    }

    searchDestinationApi = (searchText) => {
        const obj = {
            inputText: searchText ? searchText : '',
            locationType: 'destination',
            agent_id: this.state.vendorDetail ? this.state.vendorDetail.agentId : 'BESTTRIP1'
        };
        fetch(API_PATH + 'getBestTripLocation', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then((data) => {
            if(data && data.length) {
                data = data.map((item) => {
                    item['value'] = item['name'];
                    item['label'] = item['name']
                    return item;
                })
            } else {
                this.setState({searching: true});
                data = [];
            }
            this.setState({ destinationList: data })
        }).catch(console.log)
    }

    onSelectDestination = (destination) => {
        this.props.onSelectDestination(destination);
    }

    onModalClose = () => {
        this.props.onModalClose();
    }

    clearSearch = () => {
        this.setState({destinationList : []})
        this.inputTitle.value = "";
    }

    componentWillMount = () => {
        this.setVendor();
    }

    setVendor = () => {
        const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
        this.setState({vendorDetail});
    }

    render() {
        return (
            <div className="search-modal">
                <div className="autocomplete">
                    <FontAwesomeIcon onClick={this.onModalClose} icon="arrow-left" size="lg" />
                    <input ref={el => this.inputText = el} autoFocus type="text" onChange={(evt) => this.searchDestination(evt) } placeholder="Enter city, country or region" className="form-control" ></input>
                    { this.searchText && this.searchText.length > 0 &&
                        <FontAwesomeIcon onClick={this.clearSearch} icon="window-close" size="lg" />
                    }
                </div>
                <ul className="ui-menu">
                    { this.state.destinationList && this.state.destinationList.length > 0 &&
                        this.state.destinationList.map((data, index) =>
                        <li key={index} onClick={() => this.onSelectDestination(data)} className="ui-menu-item"> {data.name} </li>
                    )}
                    { this.state.destinationList.length === 0 && this.state.searching &&
                        <li>Could not find. Please enter a city, region, or country.</li>
                    }
                </ul>
            </div>
        )
    }
}

export default SearchModal