import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.scss';
import MyPlan from './components/myplan/Myplan';
import Home from './components/home/Home';
import Landing from './components/landing/Landing';
import Header from '../src/components/header/Header';
import FamilyInfo from '../src/components/travellersInfo/FamilyInfo';
import Payment from '../src/components/Payment';
import DateInfo from './components/travellersInfo/DateInfo';
import Confirmation from './components/Confirmation';
import Privacy from './components/privacy/Privacy';
import Terms from './components/terms/Terms';
import CustomizePlan from './components/customizeplan/customizeplan';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faRupeeSign, faTimes, faStar, faArrowLeft,
    faPlusCircle, faCheckCircle, faPlane, faWindowClose,
    faHotel, faHamburger, faUniversity, faCross
} from '@fortawesome/free-solid-svg-icons';
import { Vendors } from './Constants';

library.add([faRupeeSign, faTimes, faStar, faArrowLeft,
    faPlusCircle, faCheckCircle, faPlane, faWindowClose,
    faHotel, faHamburger, faUniversity, faCross
])

class MainPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false
        }
    }

    componentWillMount = () => {
        this.setVendor();
        setTimeout(() => {
            this.props.onFlagChange();
        }, 1000);
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.onFlagChange();
        }, 1000);
    }

    setVendor = () => {
        const vendorDetail = Vendors[window.location.host] ? Vendors[window.location.host] : Vendors['www.' + window.location.host];
        this.setState({vendorDetail});
        let selectedTheme = 'default-theme';
        if (vendorDetail) {
            selectedTheme = vendorDetail.theme;
        }
        const htmlTag = document.getElementsByTagName('html');
        if (htmlTag && htmlTag.length) {
          htmlTag[0].className = selectedTheme;
        }
    }


    render() {
        const loaderDiv =   <div className="yaana-loader" >
                                <Loader type="Oval" visible="true" color="#1a8cd2" height={80} width={80} />
                            </div>
        return (
            <Router>
                <div className="yaana-body" >
                    { this.state.loader ? loaderDiv : ''}
                    <Header/>
                    <div className="yaana-container w-100">
                        <Route exact path="/" component={DateInfo} />
                        {/* <Route exact path="/home" component={Home} /> */}
                        <Route path="/my-plans" component={MyPlan} />
                        <Route path="/customize" component={CustomizePlan} />
                        <Route path="/family-info" component={FamilyInfo} />
                        <Route path="/date-info" component={DateInfo} />
                        <Route path="/payment/:pnrNumber" component={Payment} />
                        <Route path="/confirmation" component={Confirmation} />
                        <Route path="/privacy" component={Privacy} />
                        <Route path="/terms" component={Terms} />
                    </div>
                </div>
            </Router>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.loader
    }
}

const mapDispachToProps = (dispach) => {
    return {
        onFlagChange: () => dispach({type: 'SHOW_LOADER'})
    }
}

export default connect(mapStateToProps, mapDispachToProps)(MainPage);