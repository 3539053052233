import React, { Component } from 'react';
import './tabs.scss';

class Tabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
          activeTab: 'desc'
        };
    }

    handleChange = (activeTab) => {
      this.setState({activeTab})
      this.props.onTabChange(activeTab);
    };

    render() {
        return (
          <div className="sticky-tabs" >                                
            {this.props.tabList && this.props.tabList.length &&
            this.props.tabList.map((tab, index) =>
              <span key={index} className={this.state.activeTab === tab.key ? 'active': ''} onClick={() => this.handleChange(tab.key) } >{tab.label}</span>
            )}
          </div>
        )
    }

}
export default Tabs