import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import Select from 'react-select';

class FamilyInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
          options: ['Family', 'Friends'],
          defaultOption: 'Family',
          planId: ''
        };
    }

    componentWillMount = () =>{
    }

    componentDidMount() {
        ReactGA.ga('send', 'pageview', '/family-info');
        const { match: { params } } = this.props;
        this.setState({planId: params.planId});
    }

    _onSelect (e) {
      console.log(e);
    }

    render() {
        return (
            <div className="my-plan h-100 mh-100" >
                <section className="text-center">
                <article className="row m-0 justify-content-center">
                    <div className="col-12 my-3 p-2">Along with</div>
                    <div className="col-8">
                    <Select
                        value={this.state.defaultOption}
                        onChange={this._onSelect}
                        options={this.state.options}
                        isSearchable={false}
                    />
                    </div>
                    {/* <input className="form-control text-center col-6 my-3" type="text" name="" defaultValue="Family" ></input> */}
                </article>
                <article className="">
                    <div className="row m-0">
                        <span className="col-12 my-3 p-2">That Includes </span>
                    </div>
                    <div className="row py-3 m-2">
                        <p className="col-2"></p>
                        <input className="form-control col-6" type="number" name="" defaultValue="0"></input>
                        <p className="col-4 p-1">Adults</p>
                    </div>
                    <div className="row py-3 m-2">
                        <p className="col-2"></p>
                        <input className="form-control col-6" type="number" name="" defaultValue="0"></input>
                        <p className="col-4 p-1">Children</p>
                    </div>
                    <div className="row py-3 m-2">
                        <p className="col-2"></p>
                        <input className="form-control col-6" type="number" name="" defaultValue="0"></input>
                        <p className="col-4 p-1">Infants</p>
                    </div>
                </article>
                <article className="row m-0 nav-bar">
                    <div className="col-9 text-center">
                        <Link to={`/date-info`} className="white-text bg-warning w-100 d-block py-2 text-decoration-none" >Continue</Link>
                    </div>
                    <p className="col-3 py-2 m-0 text-center text-link">skip</p>
                </article>
            </section>
            </div>
        )
    }
}
export default FamilyInfo