
const initialState = {
    loader: false
}

const Reducer = (state = initialState, action) => {
    const newState = {...state};
    if(action.type === 'SHOW_LOADER') {
        newState.loader = true
    }
    return newState;
}

export default Reducer;