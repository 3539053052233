export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE'
}

export const REDIRECTION_PATH = 'localhost:4200'; // local

// export const REDIRECTION_PATH = 'www.ourtourplanner.com'; // dev

// Api url dev
// export const API_PATH = 'https://ysbkawfk2e.execute-api.ap-southeast-1.amazonaws.com/Prod/';

// Api url prod
export const API_PATH = 'https://ezk1xmweo1.execute-api.ap-south-1.amazonaws.com/Prod/';

// Login Path
export const LOGIN_PATH = 'https://thebesttrip-uat.auth.ap-southeast-1.amazoncognito.com/login?response_type=code&client_id=3k9ukl1labp33s2i8t56psf2n3&redirect_uri=https://' + REDIRECTION_PATH + '/blank';

export let PaymentList = [];

export const BookingInput = [
  {
    "slot_pnr_no": "SBT501195149",
    "bookingInput": {
      "adult": "2",
      "children": "0",
      "infant": "0",
      "mobile_no": "",
      "country_code": "+91",
      "email_id": "",
      "Passengers": [
        {
          "first_name": "",
          "last_name": "dummy",
          "title": "dummy",
          "gender": "dummy",
          "roomNo": "1"
        },
        {
          "first_name": "dummy",
          "last_name": "dummy",
          "title": "dummy",
          "gender": "dummy",
          "roomNo": "1"
        }
      ],
      "ChildPassengers": [],
      "InfantPassengers": []
    }
  }
]

export const UserInput = {
  "destination": {
    "name": "Alleppey, Kerala, India",
    "placeId": "ChIJYWspqvGECDsRWvhBLFVkR7g",
    "lat": "9.498066699999999",
    "lon": "76.3388484"
  },
  "source": {
    "name": "Bangalore, Karnataka, India",
    "placeId": "ChIJbU60yXAWrjsR4E9-UejD3_g",
    "lat": "12.9715987",
    "lon": "77.5945627"
  },
  "startDateTime": "2019-08-13T00:00:00.000+05:30",
  "endDateTime": "2019-08-16T00:00:00.000+05:30",
  "adults": {
    "count": "2"
  },
  "children": {
    "count": "0",
    "age": []
  },
  "infants": {
    "count": "0",
    "age": []
  },
  "purpose": "leisure",
  "pagination": {
    "start": "0",
    "count": "10",
    "sort_by": "cost",
    "order": "asc"
  },
  "rooms": "1",
  "roomsOccupancy": [
    {
      "adults": {
        "count": "1"
      },
      "children": {
        "count": "0",
        "age": []
      },
      "infants": {
        "count": "0",
        "age": []
      }
    }
  ],
  "Agent": {
    "agent_id": "BT1"
  }
}

export const Vendors = {
  'localhost:3000': {
    id: 'localhost:3000',
    logoExtLink: '',
    logoRouteLink: '/',
    logoExtLink: '',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/HotelLogos/BestTripHotelsLogo.png',
    theme: 'monarch-theme',
    agentId: 'BT1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'Monarch Experience',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/monarch.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/room.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        }
      ],
      experiance: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai-explore.png',
          label: 'A Smarter Way to Plan Your Trip'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai-explore.png',
          label: 'A Smarter Way to Plan Your Trip'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai-explore.png',
          label: 'A Smarter Way to Plan Your Trip'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai-explore.png',
          label: 'A Smarter Way to Plan Your Trip'
        },
      ],
      quickDemo: {
        active: true,
        heading: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: true,
      videoList:['y9SgaWRM_Pg', '4v-yjd60LOM', 'oLHh7lF0pYo', 'lu6EmsauG44', 'ZUvmlr-QI5A', 'P15JMir-hCk', 'MtKQmk0teWY', 'AjzgnA8SK-k', 'xK1ioymxWS8', 'F8e7OO0a73o', 'qJakT51glJI', 'P15JMir-hCk'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: '/terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: '/privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Bangalore',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'team@goyaana.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9789019336',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9845856738',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ],
    paymentPageConfig: {
      ContactDetails: {
        header: 'Share Your Contact Details',
        subHeader: 'Contact Details Sub Header'
      },
      submitLabel: 'Submit'
    }
  },
  'www.m.ourtourplanner.com': {
    id: 'm.ourtourplanner.com',
    logoExtLink: '',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/HotelLogos/BestTripHotelsLogo.png',
    theme: 'monarch-theme',
    agentId: 'BESTTRIP1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'Monarch Experience',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/monarch.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        }
      ],
      quickDemo: {
        active: true,
        heading: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: true,
      videoList:['y9SgaWRM_Pg', '4v-yjd60LOM', 'oLHh7lF0pYo', 'lu6EmsauG44', 'ZUvmlr-QI5A', 'P15JMir-hCk', 'MtKQmk0teWY', 'AjzgnA8SK-k', 'xK1ioymxWS8', 'F8e7OO0a73o', 'qJakT51glJI', 'P15JMir-hCk'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: 'www.hotelsmonarch.com'
        },
        {
          label: 'Terms',
          routeLink: '',
          extUrl: 'www.hotelsmonarch.com/terms-and-conditions/'
        },
        {
          label: 'Privacy',
          routeLink: '',
          extUrl: 'www.hotelsmonarch.com/terms-and-conditions/'
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: 'www.hotelsmonarch.com'
            },
            {
              label: 'Privacy Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: '',
              extUrl: 'www.hotelsmonarch.com/terms-and-conditions/'
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Fairmount by metro, Plot No 4 & 6, Off Palm Beach Rd, Sector 17, Sanpada, Navi Mumbai, Maharashtra 400705',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'info@hotelsmonarch.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 74984 11118',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 74984 11118',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ]
  },
  'www.m.monarch-planner.goyaana.com': {
    id: 'm.monarch-planner.goyaana.com',
    logoExtLink: '',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/HotelLogos/monarch-group-logo.svg',
    theme: 'grey-theme',
    agentId: 'MONARCH1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'Monarch Experience',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/monarch.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        }
      ],
      quickDemo: {
        active: false,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: false,
      videoList:['y9SgaWRM_Pg', '4v-yjd60LOM', 'oLHh7lF0pYo', 'lu6EmsauG44', 'ZUvmlr-QI5A', 'P15JMir-hCk', 'MtKQmk0teWY', 'AjzgnA8SK-k', 'xK1ioymxWS8', 'F8e7OO0a73o', 'qJakT51glJI', 'P15JMir-hCk'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: 'www.hotelsmonarch.com'
        },
        {
          label: 'Terms',
          routeLink: '',
          extUrl: 'www.hotelsmonarch.com/terms-and-conditions/'
        },
        {
          label: 'Privacy',
          routeLink: '',
          extUrl: 'www.hotelsmonarch.com/terms-and-conditions/'
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: 'www.hotelsmonarch.com'
            },
            {
              label: 'Privacy Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: '',
              extUrl: 'www.hotelsmonarch.com/terms-and-conditions/'
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Fairmount by metro, Plot No 4 & 6, Off Palm Beach Rd, Sector 17, Sanpada, Navi Mumbai, Maharashtra 400705',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'info@hotelsmonarch.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 74984 11118',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 74984 11118',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ]
  },
  'www.m.demohotel-planner.goyaana.com': {
    id: 'm.demohotel-planner.goyaana.com',
    logoExtLink: '',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/logo.png',
    theme: 'grey-theme',
    agentId: 'DEMOHOTEL1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'Unique Experiences',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        }
      ],
      quickDemo: {
        active: true,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: true,
      videoList:['y9SgaWRM_Pg', '4v-yjd60LOM', 'oLHh7lF0pYo', 'lu6EmsauG44', 'ZUvmlr-QI5A', 'P15JMir-hCk', 'MtKQmk0teWY', 'AjzgnA8SK-k', 'xK1ioymxWS8', 'F8e7OO0a73o', 'qJakT51glJI', 'P15JMir-hCk'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Bangalore',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'team@goyaana.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9789019336',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9845856738',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ]
  },
  'www.m.fortunehotels-planner.goyaana.com': {
    id: 'm.fortunehotels-planner.goyaana.com',
    logoExtLink: '',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/HotelLogos/fortune-hotel-logo.png',
    theme: 'grey-theme',
    agentId: 'FORTUNE1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'Fortune Experiences',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Unique Experiences'
        }
      ],
      quickDemo: {
        active: false,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: false,
      videoList:['y9SgaWRM_Pg', '4v-yjd60LOM', 'oLHh7lF0pYo', 'lu6EmsauG44', 'ZUvmlr-QI5A', 'P15JMir-hCk', 'MtKQmk0teWY', 'AjzgnA8SK-k', 'xK1ioymxWS8', 'F8e7OO0a73o', 'qJakT51glJI', 'P15JMir-hCk'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Fortune Park Hotels Limited, ITC Green Centre, 10 Institutional Area, Sector 32, Gurgaon- 122 001, Haryana.',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'rmo.north@fortunehotels.in',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+1-800-102-2333',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ]
  },
  'www.m.goyaana.com': {
    id: 'm.goyaana.com',
    logoExtLink: 'https://www.goyaana.com',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/logo.png',
    theme: 'grey-theme',
    agentId: 'BT1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'How It Works',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/works.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Experiences | Hidden Gems | Attractions'
        }
      ],
      quickDemo: {
        active: true,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: true,
      videoList:['y9SgaWRM_Pg', '4v-yjd60LOM', 'oLHh7lF0pYo', 'lu6EmsauG44', 'ZUvmlr-QI5A', 'P15JMir-hCk', 'MtKQmk0teWY', 'AjzgnA8SK-k', 'xK1ioymxWS8', 'F8e7OO0a73o', 'qJakT51glJI', 'P15JMir-hCk'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Bangalore',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'team@goyaana.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9789019336',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9845856738',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      // {
      //   key: 'Flight',
      //   label: 'Flight',
      //   icon: 'plane'
      // }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ]
  },
  'www.m.planner.goyaana.com': {
    id: 'www.m.planner.goyaana.com',
    logoExtLink: 'https://www.goyaana.com',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/logo.png',
    theme: 'grey-theme',
    agentId: 'BT1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'How It Works',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/works.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Experiences | Hidden Gems | Attractions'
        }
      ],
      quickDemo: {
        active: true,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: true,
      videoList:['y9SgaWRM_Pg', '4v-yjd60LOM', 'oLHh7lF0pYo', 'lu6EmsauG44', 'ZUvmlr-QI5A', 'P15JMir-hCk', 'MtKQmk0teWY', 'AjzgnA8SK-k', 'xK1ioymxWS8', 'F8e7OO0a73o', 'qJakT51glJI', 'P15JMir-hCk'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Bangalore',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'team@goyaana.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9789019336',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9845856738',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ],
    paymentPageConfig: {
      ContactDetails: {
        header: 'Contact Details',
        subHeader: 'Please leave your coordinates here. We will send your plan to access on-the-go and share offers from our partners at the destination.'
      },
      submitLabel: 'Submit'
    }
  },
  'www.m.plannerbeta.thebesttrip.in': {
    id: 'www.m.plannerbeta.thebesttrip.in',
    logoExtLink: 'https://www.goyaana.com',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/logo.png',
    theme: 'grey-theme',
    agentId: 'BT1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'How It Works',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/works.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Experiences | Hidden Gems | Attractions'
        }
      ],
      quickDemo: {
        active: true,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: true,
      videoList:['y9SgaWRM_Pg', '4v-yjd60LOM', 'oLHh7lF0pYo', 'lu6EmsauG44', 'ZUvmlr-QI5A', 'P15JMir-hCk', 'MtKQmk0teWY', 'AjzgnA8SK-k', 'xK1ioymxWS8', 'F8e7OO0a73o', 'qJakT51glJI', 'P15JMir-hCk'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Bangalore',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'team@goyaana.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9789019336',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9845856738',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ],
    paymentPageConfig: {
      ContactDetails: {
        header: 'Contact Details',
        subHeader: 'Please leave your coordinates here. We will send your plan to access on-the-go and share offers from our partners at the destination.'
      },
      submitLabel: 'Submit'
    }
  },
  'www.m.clarksinn-planner.goyaana.com': {
    id: 'm.clarksinn-planner.goyaana.com',
    logoExtLink: '',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/ClarksInn/clarksinn-logo1.jpeg',
    theme: 'grey-theme',
    agentId: 'Clarksinn1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'Clarksinn Experiences',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Unique Experiences'
        }
      ],
      quickDemo: {
        active: false,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: false,
      videoList:['CTrMPX55lm0', 'yN7vKPQ1YdU', 'VIujD_5fiHY'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Clarks Inn Group of Hotels, D.no 51/1-1a-4, Kallur road 518003 Kurnool',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'centralreservations@clarksinn.in',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9717170572',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ]
  },
  'www.m.royalorchid-planner.goyaana.com': {
    id: 'm.royalorchid-planner.goyaana.com',
    logoExtLink: '',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/RoyalOrchid/orchid_logo.png',
    theme: 'grey-theme',
    agentId: 'Orchid1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'RoyalOrchid Experiences',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Unique Experiences'
        }
      ],
      quickDemo: {
        active: false,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: false,
      videoList:['lGjwoGM9Yr4', 'k2MGzoieKw0', 'dg-wJXLEgTc'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Royal Orchid Brindavan Garden Palace & Spa, Brindavan Gardens Krishna Raja Sagara, Mysore',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'fom.brindavan@royalorchidhotels.com/',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9902000153',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ]
  },
  'www.m.rareindia-planner.goyaana.com': {
    id: 'm.rareindia-planner.goyaana.com',
    logoExtLink: 'https://www.rareindia-experiences.goyaana.com',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/RareIndia/rare_logo.png',
    theme: 'grey-theme',
    agentId: 'RARE1',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'RareIndia Experiences',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Unique Experiences'
        }
      ],
      quickDemo: {
        active: false,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: false,
      videoList:['lGjwoGM9Yr4', 'k2MGzoieKw0', 'dg-wJXLEgTc'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Niraamaya Retreats Cardamom Club, Thekkady || Windermere Retreat, Munnar',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'info@rareindia.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 9810265781',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ]
  },
  'www.m.svenskahotels-planner.goyaana.com': {
    id: 'm.svenskahotels-planner.goyaana.com',
    logoExtLink: '',
    logoRouteLink: '/',
    logo: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/HotelLogos/svenska-logo.png',
    theme: 'grey-theme',
    agentId: 'SVENSKA',
    showPrice: false,
    landingPage: {
      howItWorks: {
        heading: 'SVENSKA Experiences',
        active: true,
        url: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/m_GY_homepage_1.png'
      },
      carousel: [
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Explore our self-service platform that gives you the freedom to design your trip exactly the way you want and help you get a great deal!'
        },
        {
          img: 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/mumbai_mobile.jpg',
          label: 'A Smarter Way to Plan Your Trip',
          subLabel: 'Unique Experiences'
        }
      ],
      quickDemo: {
        active: false,
        label: 'Quick Demo',
        url: 'KBFxzsGaa7o'
      }
    },
    destinationVideos: {
      active: false,
      videoList:['lGjwoGM9Yr4', 'k2MGzoieKw0', 'dg-wJXLEgTc'],
    },
    menu: {
      mainMenu: [
        {
          label: 'Home',
          routeLink: '/',
          extUrl: ''
        },
        {
          label: 'About Us',
          routeLink: '',
          extUrl: ''
        },
        {
          label: 'Terms',
          routeLink: 'terms',
          extUrl: ''
        },
        {
          label: 'Privacy',
          routeLink: 'privacy',
          extUrl: ''
        }
      ],
      footer: [
        {
          name: 'Help',
          childrens: [
            {
              label: 'FAQ',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'About Us',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Privacy Policy',
              routeLink: 'privacy',
              extUrl: ''
            },
            {
              label: 'Copyright Policy',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'Terms of Use',
              routeLink: 'terms',
              extUrl: ''
            },
            {
              label: 'Blogs',
              routeLink: '',
              extUrl: ''
            }
          ]
        },
        {
          name: 'About Us',
          childrens: [
            {
              label: 'Svenska Design Hotels',
              routeLink: '',
              extUrl: ''
            },
            {
              label: 'luxury.mumbai@svenskaxxxx.com',
              routeLink: '',
              extUrl: ''
            },
            {
              label: '+91 2244310011',
              routeLink: '',
              extUrl: ''
            }
          ]
        }
      ]
    },
    tabList: [
      {
        key: 'Activity',
        label: 'Experience',
        icon: 'university'
      },
      {
        key: 'Hotel',
        label: 'Hotel',
        icon: 'hotel'
      },
      {
        key: 'Attraction', // This is l1category key
        label: 'Attraction',
        icon: 'university'
      },
      {
        key: 'Shopping',
        label: 'Shopping',
        icon: 'university'
      },
      {
        key: 'Restaurant',
        label: 'Restaurant',
        icon: 'hamburger'
      },
      {
        key: 'Flight',
        label: 'Flight',
        icon: 'plane'
      }
    ],
    destinationList:[
      {
        "name": "Manali, Himachal Pradesh, India",
        "placeId": "ChIJP9A_FgiHBDkRzXZQvg6oKYE",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/manali-2035750_1280.jpg',
        "lat": "32.2396325",
        "lon": "77.1887145"
      },
      {
        "name": "Munnar, Kerala, India",
        "placeId": "ChIJbZoJTXmZBzsRDH48VeVQMgY",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/munnar_place.jpg',
        "lat": "10.0889333",
        "lon": "77.05952479999999"
      },
      {
        "name": "Shimla, Himachal Pradesh, India",
        "placeId": "ChIJZ25d4-N4BTkRt1Sf__Z_fh8",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/shimla-555892_1280.jpg',
        "lat": "31.1048145",
        "lon": "77.17340329999999"
      },
      {
        "name": "Goa, India",
        "placeId": "ChIJQbc2YxC6vzsRkkDzYv-H-Oo",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/Goa_place.jpg',
        "lat": "15.2993265",
        "lon": "74.12399599999999"
      },
      {
        "name": "Delhi - Agra, India",
        "placeId": "ChIJLbZ-NFv9DDkRQJY4FbcFcgM",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/delhi_place.jpg',
        "lat": "28.7040592",
        "lon": "77.10249019999999"
      },
      {
        "name": "Nainital, Uttarakhand, India",
        "placeId": "ChIJYZ39KLyhoDkRs32YFql7rnw",
        "image": 'https://ourtourplannerpublic.s3-ap-southeast-1.amazonaws.com/nainital_place.jpg',
        "lat": "29.3803039",
        "lon": "79.4635658"
      }
    ],
    paymentPageConfig: {
      ContactDetails: {
        header: 'Contact Details',
        subHeader: 'Please leave your coordinates here. We will send your plan to access on-the-go and share offers from our partners at the destination.'
      },
      submitLabel: 'Submit'
    },
    confirmPageHTML: `<div class="text-center my-3 fs-24">
                          <span class="d-block">Thank You. We got your requirements!</span>
                          <span class="d-block"></span>
                          <span class="d-block">Our team will leave no stones unturned to make your plan into a beautiful reality.</span>
                          <span class="d-block">In the meantime if there is anything, please shoot an email to <b><a href="mailto:team@goyaana.com"> team@goyaana.com</a></b>.</span>
                      </div>
                      `
  },
};