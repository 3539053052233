import React, { Component } from 'react';
import './terms.scss';
import ReactGA from 'react-ga';

class Terms extends Component {

    componentDidMount = () => {
        ReactGA.ga('send', 'pageview', '/terms');
    }

    render() {
        return (
            <section className="mt-4">
                <ol>
                        <h2>DRAFT FOR DISCUSSIONS<br />
                            (Strictly Confidential)
                        </h2>
                        <h2 className="text-center">TERMS AND CONDITIONS</h2>
                        <li>INTRODUCTION
                            <ol>
                                <li><span>Bestriptech Solution Private Limited</span>, including its subsidiaries, affiliates,
                                    associate/group
                                    companies and licensors (collectively “<span>Company</span>”, “<span>we</span>”, “<span>us</span>”
                                    or “<span>our</span>”) welcomes you,
                                    irrespective of whether you are a mere visitor or a member (the “<span>Customer</span>”, “<span>Subscriber</span>”,
                                    “<span>User</span>”, “<span>you</span>” or “<span>your</span>”) to our Platform (as defined below).
                                </li>
                                <li>
                                    These Terms (as defined below) set out the essential terms and conditions that govern: (i) our
                                    relationship with you and clearly define and document our rights, responsibilities and
                                    obligations; (ii) your access to and use of the Platform and the Services (as defined below)
                                    provided by the Company; and (iii) the transactions and activities undertaken by you pursuant
                                    to the Services. These Terms are incorporated into and form part of the contract between you
                                    and us, including when you register yourself with us.
                                </li>
                            </ol>
                        </li>
                        <li>TERMS AND CONDITIONS
                            <ol>
                                <li>These Terms constitute an electronic record within the meaning of Applicable Law (as defined
                                    below) and do not require any physical or digital signatures. These Terms are published in
                                    compliance with, and are governed by, the provisions of Indian law, including but not limited to:
                                    (i) the Indian Contract Act, 1872; and (ii) the (Indian) Information Technology Act, 2000 and the
                                    rules, regulations, guidelines and clarifications framed there under, including the (Indian)
                                    Information Technology (Reasonable Security Practices and Procedures and Sensitive
                                    Personal Information) Rules, 2011 and the (Indian) Information Technology (Intermediaries
                                    Guidelines) Rules, 2011.
                                </li>
                                <li>
                                    Please read these Terms carefully before using the Platform and the Services. These Terms
                                    apply to all Users of the Platform and the Services, irrespective of the mode and the manner in
                                    which they have accessed the Platform and the Services.
                                </li>
                                <li>
                                    Use of the Platform and Services is available only to Persons (as defined below) who can enter
                                    into legally binding contracts under Applicable Law. The Services are not targeted towards, nor
                                    intended for use by, anyone under the age of 18 (Eighteen) or anyone who is disqualified or not
                                    competent to enter into a contract under the Indian Contract Act, 1872 or any other Applicable
                                    Law. By using the Platform or the Services, you represent and warrant that you are 18
                                    (Eighteen) years of age or older and competent to enter into a contract. If you are under the
                                    age of 18 (Eighteen) or not competent to enter into a contract, you shall not, under any
                                    circumstances or for any reason, use the Platform or the Services. We may, in our sole
                                    discretion, refuse to offer the Services to any Person and/or change its eligibility criteria at
                                    any
                                    time.
                                </li>
                                <li>
                                    You are solely responsible for ensuring that these Terms are in compliance with all Laws
                                    applicable to you and the right to access the Platform and the Services is revoked where the
                                    Platform or the Services is prohibited or to the extent offering, sale or provision of the Services
                                    conflicts with any Applicable Law.
                                </li>
                                <li>
                                    The Platform is operated and controlled by us in India, and the Content (as defined below)
                                    provided in or accessible through the Platform is not directed to any other jurisdiction or
                                    country, including any jurisdiction or country where such distribution or use would be contrary to
                                    Law or that would subject the Company to any registration or other requirement within such
                                    jurisdiction or country. Access to the Platform/Services from countries, territories and
                                    jurisdictions where such access is illegal, is prohibited.
                                </li>
                                <li>
                                    If you are using the Services on behalf of an entity, company or other organization/association,
                                    you represent and warrant that you: (i) have the authority to bind such entity, company or
                                    organization/association to these Terms; and (ii) agree to be bound by these Terms on behalf of
                                    such entity, company or organization/association.
                                </li>
                                <li>
                                    If you are using the Services on behalf of any other Person (whether under his authority or
                                    otherwise), you represent and warrant that you: (i) have the authority to bind such Person to
                                    these Terms; and (ii) agree to be bound by these Terms on behalf of such Person.
                                </li>
                                <li>
                                    Accessing, browsing or using the Platform and/or the Services constitutes your unconditional
                                    acceptance and agreement to be bound by these Terms.
                                </li>
                                <li>
                                    By using our Platform and/or the Services, you agree that we may use and share your personal
                                    information and/or sensitive personal data or information in accordance with the terms of our
                                    Privacy Policy (as defined below).
                                </li>
                                <li>
                                    The Company reserves the right, at any time and from time to time, to amend, modify, revise,
                                    update, suspend, remove and/or otherwise change these Terms without prior notice to you. All
                                    such amendments and modifications shall take effect immediately when posted on the
                                    Platform. By continuing to access or browse the Platform or use the Services after any such
                                    amendments or modifications, you agree to be bound by such amended or modified Terms. For
                                    this reason, we encourage you to review these Terms whenever you access the Platform or use
                                    the Services. If you do not agree to any change to these Terms, you must immediately stop
                                    using the Platform and the Services.
                                </li>

                            </ol>
                        </li>
                        <li>DEFINITIONS AND INTERPRETATION
                            <ol>
                                <li><span>Definitions:</span> In these Terms, the following words and expressions shall bear the
                                    meanings
                                    assigned to them below:
                                    <ol>
                                        <li><span>“Applicable Laws”</span> or <span>“Laws”</span> means all applicable laws, statutes,
                                            enactments, acts of
                                            legislature or parliament, ordinances, judgments, decrees, injunctions, writs, rules, by-
                                            laws, regulations, notifications, circulars, guidelines, policies, directions, directives,
                                            demands conventions, orders, interpretations, licenses and/or permits of all
                                            Governmental Authorities of all relevant jurisdictions;
                                        </li>
                                        <li>
                                            <span>“Company’s Marks”</span> means the trademarks, service marks, trade names, service
                                            names, brand names, brand marks, internet domain names, identifying symbols, logos,
                                            emblems, signs or insignia, owned by the Company and its affiliates and licensors;
                                        </li>
                                        <li>
                                            <span>“Company’s Technology”</span> means the algorithms, software, hardware, code,
                                            technology, devices or other functional items, processes, systems, methods of
                                            operation, concepts, interfaces, web properties, communication pathways and/or
                                            protocols used by the Company to provide the Services, including the Platform;
                                        </li>
                                        <li>
                                            <span>“Company’s Trade Secret”</span> includes the business idea, commercial secrets,
                                            commercial method, know how, compilation of information, practice, pattern, technology,
                                            programs, systems, processes, compositions, technological data, technological
                                            prototypes, sales methods, distribution methods, profiles, advertising and marketing
                                            strategies, and other proprietary information developed by Company that: (i) provides
                                            Company with a competitive and economic advantage; (ii) is treated by Company in a
                                            way that can reasonably be expected to prevent the public or competitors from learning
                                            about it; and (iii) Company actively makes efforts to maintain as a secret;
                                        </li>
                                        <li>
                                            <span>“Government”</span> or <span>“Governmental Authority”</span> means the union, state,
                                            local or other
                                            governmental authority exercising executive, legislative, judicial, regulatory or
                                            administrative functions of or pertaining to government, or any other governmental
                                            authority or statutory authority, department, agency, commission, organization, board,
                                            tribunal, court and/or other entity authorized to make Laws;
                                        </li>
                                        <li>
                                            <span>“Losses”</span> means and include all losses, liabilities, damages, interest,
                                            penalties, claims,
                                            costs and expenses (including those arising out of or based upon or in relation to any
                                            judgment, award, settlement, and reasonable attorneys’ fees and other costs related
                                            thereto);
                                        </li>
                                        <li>
                                            <span>“Partner(s)”</span> includes any Person providing any goods, services and other
                                            facilities of
                                            whatsoever nature related directly or indirectly to your Travel such as accommodation,
                                            reservation, booking, transportation, food, destination management or other services,
                                            including hotels, resorts, restaurants, airline companies, bus operators, cab operators,
                                            travel agencies, travel arrangers, travel managers, online travel agencies, online travel
                                            companies, tour operators, destination marketing organizations, travel/tourist guides,
                                            online hotel aggregators, online airline aggregators, transportation and logistics
                                            management service providers, hotel and accommodation management service
                                            providers, hospitality service providers and destination management companies;
                                        </li>
                                        <li>
                                            <span>“Partner Services”</span> means the products and services of whatsoever nature
                                            provided by
                                            the Partners, including in connection with your Travel, whether via the Platform or
                                            otherwise;
                                        </li>
                                        <li>
                                            <span>“Partner Terms and Conditions”</span> means the terms, conditions, rules,
                                            regulations,
                                            policies and other agreements on which the Partners provide the Partner Services;
                                        </li>
                                        <li>
                                            <span>“Person”</span> means any natural person, limited or unlimited liability company,
                                            corporation,
                                            partnership (whether of limited liability or unlimited liability), proprietorship, Hindu
                                            undivided family, trust, union, association, Government or any agency or political
                                            subdivision thereof or any other entity that may be treated as a person under Applicable
                                            Law;
                                        </li>
                                        <li>
                                            <span>“Platform”</span> means https://www.goyaana.com/, its sub-domains and all
                                            other
                                            internet domains, mobile applications, widgets, and other downloadable applications
                                            developed, owned, operated and/or controlled by Company and its affiliates/licensors,
                                            and branded or licensed exclusively under the Company’s Marks, whether now existing
                                            or hereinafter developed, through which Company offers and provides the Services;
                                        </li>
                                        <li>
                                            <span>“Privacy Policy”</span> means the Company’s privacy policy, located at [●];
                                        </li>
                                        <li>
                                            <span>“Services”</span> the products, services, contents, features, applications, tools,
                                            data,
                                            documents and information offered, made available or enabled by the Company through
                                            the Platform (or through third party service providers featured on the Platform) from time
                                            to time, including the services described in Paragraph 5.1 below;
                                        </li>
                                        <li>
                                            <span>“Terms”</span> includes: (i) these terms and conditions; (ii) the Privacy Policy;
                                            (iii) such other
                                            guidelines, rules, policies and procedures posted by the Company on the Platform, from
                                            time to time (each of which is incorporated herein by reference and forms an integral
                                            part of these Terms); and (iv) any other agreements and terms and conditions entered
                                            into with the Company that govern: (a) your relationship with the Company; (b) your use
                                            of the Platform and the Services, as amended, modified and supplemented from time to
                                            time;
                                        </li>
                                        <li>
                                            <span>“Travel”</span> includes a trip, tour, sightseeing, exploration, outing, drive,
                                            journey,
                                            excursion,
                                            holiday, vacation or expedition of whatsoever nature, irrespective of the duration; and
                                        </li>
                                        <li>
                                            <span>“Travel Information”</span> includes all information related to Travel, including
                                            information on
                                            the country, state, region, city, town, destination, location, tourist attractions, routes,
                                            hotels, resorts, restaurants, holiday homes, home stays, flights, flight schedules, flight
                                            bookings and ticket prices, trains, train timetables and ticket prices, coach and bus and
                                            their timetables and ticket prices, travel duration and travel time, transfers,
                                            transportation, logistics, sightseeing, attractions, culture, cuisines, holiday packages,
                                            prices, costs, review scores and star ratings, traveller reviews and ratings, photos,
                                            pictures, graphics and videos.
                                        </li>
                                    </ol>
                                </li>
                                <li>Interpretation
                                    <ol>
                                        <li>Paragraph headings are for convenience only and do not affect the construction or
                                            interpretation of any provision of these Terms.
                                        </li>
                                        <li>In addition to the above terms, certain terms may be defined elsewhere in these Terms
                                            and wherever such terms are used in these Terms, they shall have the meaning so
                                            assigned to them.
                                        </li>
                                        <li>
                                            Where a word or phrase is defined, other parts of speech and grammatical forms and
                                            the cognate variations of that word or phrase shall have corresponding meanings.
                                        </li>
                                        <li>
                                            All references in these Terms to statutory provisions shall be statutory provisions for the
                                            time being in force and shall be construed as including references to any statutory
                                            modifications, consolidation or re-enactment (whether before or after the date of these
                                            Terms) for the time being in force and all statutory rules, regulations and orders made
                                            pursuant to a statutory provision.
                                        </li>
                                        <li>
                                            Words denoting singular shall include the plural and vice versa and words denoting any
                                            gender shall include all genders unless the context otherwise requires.
                                        </li>
                                        <li>
                                            The term “includes” or “including” shall be construed to mean without limitation.
                                        </li>
                                        <li>
                                            References to Paragraphs are, unless the context otherwise requires, references to the
                                            paragraphs of these Terms.
                                        </li>
                                        <li>
                                            All references to these Terms shall be deemed to include any amendments or
                                            modifications to these Terms, from time to time.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>COMMUNICATIONS
                            <ol>
                                <li>
                                    When you use the Platform and/or the Services or send emails or other data, information or
                                    communication to us, you agree and understand that you are communicating with us through
                                    electronic records and you consent to receive communications via electronic records (including
                                    SMS) from us periodically and as and when required, irrespective of whether you are registered
                                    under the Do not Disturb registry under Telecom Regulatory Authority of India, Government of
                                    India (TRAI) or not. Your act of accessing, browsing or using the Platform and/or the Services,
                                    or by contacting us through any means or medium, or engaging us to provide the Services
                                    constitutes a “verifiable request” by you authorising us to call or send messages, to your
                                    telephone numbers provided to or registered with us, with respect to the Services. We reserve
                                    the right to communicate with you by email or by such other mode of communication, electronic
                                    or otherwise.
                                </li>
                                <li>
                                    The Company and/or its Partners may send booking confirmation, itinerary information,
                                    cancellation, payment confirmation, refund status, schedule change or any such other
                                    information relevant for a transaction undertaken by you, via SMS or by voice call on the
                                    contact number given by you at the time of buying the relevant Partner Service or via email
                                    given by you at the time of buying the relevant Partner Service.
                                </li>
                                <li>
                                    The User hereby unconditionally consents that such communications via SMS, email and/ or
                                    voice call by the Company is: (i) upon the request and authorization of the User; (ii)
                                    ‘transactional’ and not an ‘unsolicited commercial communication’ as per the guidelines of
                                    TRAI; and (iii) in compliance with the relevant guidelines of TRAI or such other authority in India
                                    and abroad. The User shall indemnify the Company against all Losses incurred by the
                                    Company in the event any action is taken by TRAI or any other Person or Governmental
                                    Authority due to any compliant raised or made by the User against the Company with respect to
                                    the communications mentioned above or due to a wrong number or email address being
                                    provided by the User for any reason whatsoever.
                                </li>
                            </ol>
                        </li>
                        <li>USE OF THE PLATFORM/SERVICES AND LIMITED ROLE OF THE COMPANY
                            <ol>
                                <li>
                                    Subject to these Terms, the Services provided through the Platform enables you to customise
                                    and plan your Travel and related activities based on your preferences, likes and interests by
                                    providing you with: (i) the necessary Travel Information; (ii) an algorithmic approach to match
                                    your preferences, likes and interests with the most relevant Travel Information, Partners and
                                    Partner Services; (iii) access to the Partner Services and facilitate transactions with Partners
                                    for
                                    bookings, reservations and purchase of the relevant Partner Services; and (iv) such other
                                    assistance required in the planning and execution of your Travel. Further, through the Platform,
                                    the Partners (in their professional conduct of business i.e. B2C or B2B) can advertise, market,
                                    promote, offer to sell and sell (as applicable) their Partner Services and Users can discover,
                                    search, compare, and make an order, reservation for, or purchase a relevant Partner Service.
                                </li>
                                <li>
                                    You specifically understand, acknowledge and agree that the Services have been designed for
                                    informational purposes only and the role of the Company is limited to providing an intermediary
                                    service. The Company is only acting as an <span>“intermediary”</span> solely to assist Users in
                                    gathering
                                    Travel Information, determining the availability of Travel related products and services/Partner
                                    Services and transacting with the Partners. If you are purchasing a Partner Service through the
                                    Platform, your purchase transaction is made with the relevant Partner listed on the Platform and
                                    the Platform only acts as a user interface. In certain cases, the Company may complete the
                                    transaction with the Partners on your behalf.
                                </li>
                                <li>
                                    The Company is providing the Platform and its Services as a medium to enable you plan your
                                    Travel and avail the Partner Services. The Company does not offer or sell any Travel related
                                    goods and services. The display on the Platform of a Partner or Partner Service does not, in
                                    any way or manner, imply, suggest, or constitute a recommendation by the Company of that
                                    Partner or Partner Service or any sponsorship or approval of the Company by such Partner, or
                                    any affiliation between such Partner and the Company. You specifically understand,
                                    acknowledge and agree that: (i) the Platform and the Services is not: (a) promoting any Partner
                                    or Partner Services; and/or (b) recommending any Person to enter into any transaction of
                                    whatsoever nature with any Partner; (ii) the Platform and the Services are not intended to
                                    provide any professional advice with regard to your Travel.
                                </li>
                                <li>
                                    The Company is not a provider of any of the Partner Services and has no responsibility or
                                    liability whatsoever for Partner Services provided or not provided by any Partner. The Partner
                                    Services are owned, controlled, operated, managed and/or made available by the Partners
                                    either directly or through their vendors, suppliers, partners, sub-contractors, representatives
                                    and/or agents. The Partners are solely responsible for the Partner Services. The Company
                                    makes no representations or warranties regarding the Partner Services, including their
                                    standards or availability. The Company hosts the Partner Services and their content, including
                                    prices, made available by or obtained from Partners. Since the Company has no control over
                                    the Partner Services, the Company does not verify the content provided or uploaded by the
                                    Partners and the Company shall, in no way, be responsible for the accuracy, correctness,
                                    completeness and timeliness of such content. Your interaction and transactions with any
                                    Partner accessed through the Platform is at your own risk and the Company does not bear any
                                    responsibility whatsoever should anything go wrong with your purchase or during your Travel.
                                    The Company shall not be liable for any act, omission or default, whether negligent or
                                    otherwise, of any Partner or any other third party/Person. All Travel related documents and
                                    documents in connection with your purchase of a relevant Partner Service are issued by the
                                    relevant Partner and are subject to the Partner Terms and Conditions and if any documents are
                                    issued by us, we are issuing them only on behalf of the relevant Partner.
                                </li>
                                <li>
                                    All Partner Services are provided by independent Partners and are subject to the Partner Terms
                                    and Conditions imposed by such Partners. Before buying a Partner Service, it is extremely
                                    important that you read the relevant Partner’s Partner Terms and Conditions, because any
                                    purchase made, either on their website or directly through our Service, creates a contract
                                    between you and the relevant Partner. The Users shall: (i) thoroughly read the Partner Terms
                                    and Conditions and examine, verify, assess and understand the risks which are associated with
                                    transacting with a relevant Partner; and (ii) perform their own independent due diligence on the
                                    relevant Partner before transacting with such Partner. You acknowledge, understand and agree
                                    that the Company does not, in any manner whatsoever: (i) control the Partner Services
                                    provided by the Partners which is made available through the Platform; (ii) research, endorse,
                                    validate or certify the Partner Services or assumes that all Partners will be truthful, trustworthy
                                    and reliable; and (iii) warrant the accuracy and correctness of the any content, material, data
                                    and any other information submitted/uploaded by the Partner on the Platform and/or on their
                                    respective websites. The Company strongly recommends you to use the information made
                                    available to you as an initial starting point for conducting your own independent research and
                                    due diligence about a relevant Partner and Partner Service and make an independent,
                                    thoughtful and informed decision about your Travel before initiating, undertaking and concluding
                                    an order/transaction with such Partner. Given that the Company does not research, verify,
                                    endorse, represent, warrant or guarantee the truthfulness, completeness, accuracy, reliability,
                                    or veracity of any content and information provided by the Partners, you hereby acknowledge
                                    and agree that you are not relying on the Company and the Services for any decision regarding
                                    your Travel, and that you are solely responsible for: (i) determining whether a transaction is
                                    appropriate or suitable for you based on your Travel, based on your objectives, preferences,
                                    circumstance and independent judgement; and (b) all consequences arising therefrom,
                                    including any Losses which may be incurred by you.
                                </li>
                                <li>
                                    You expressly acknowledge, understand and agree that any transaction for the purchase of any
                                    of the Partner Services is solely between you and the relevant Partner and that the Company
                                    does not guarantee and is not responsible or liable in any way for the delivery, quality,
                                    reliability,
                                    safety, legality or any other aspect of such Partner Services or the transaction between you and
                                    the relevant Partner. Your engagement in any such transaction with a relevant Partner is at your
                                    sole risk.
                                </li>
                                <li>
                                    The Company is not responsible for screening, censoring or otherwise controlling transactions
                                    between you and any relevant Partner, including whether the transaction is legal and valid as
                                    per Applicable Laws. You are solely responsible for verifying the accuracy and correctness of
                                    your transactions with any relevant Partner. It is essential that you enter the details of yourself
                                    and/or each traveler (with their consent) correctly and according to the valid identification
                                    documents. Some Partners may cancel or deny a service if the traveler's name varies from the
                                    details provided by you at the time of your purchase. The Company is not responsible for any
                                    Loss arising from incorrect entry of your and/or your traveler's name or for any inability to
                                    travel
                                    as a result of the Partners Terms and Conditions.
                                </li>
                                <li>
                                    The Company does not take part in the negotiation or execution of any transaction related to or
                                    in connection with any Partner Services between you and the relevant Partner. All commercial
                                    and contractual terms are offered by and agreed to between the Users and the Partners alone,
                                    which commercial and contractual terms include without limitation the nature and kind of the
                                    Partner Services, date, period and mode of delivery of the Partner Services, warranties related
                                    to the Partner services the price, discounts and benefits offered, refunds, cancellations,
                                    payment methods, payment terms, documentation requirement such other rules and
                                    regulations of the Partners. The Company does not have any control or does not determine or
                                    advise or in any way involve itself in the offering or acceptance of such commercial and
                                    contractual terms between the Users and the Partners. You shall be solely responsible and
                                    liable to determine and agree upon the manner and terms and conditions for the transactions
                                    you enter into with the relevant Partner. The Company: (i) shall not, in any manner whatsoever,
                                    be responsible for any non-performance or breach of any contract entered into between Users
                                    and the Partners; (ii) bears no liability to any User or Person for any failure of a User and/or
                                    Partner to fulfil his/her/its obligations under any deal or transaction entered into between them;
                                    (iii) cannot and does not guarantee that the concerned User and/or the Partner to a deal or
                                    transaction will perform any deal or transaction concluded pursuant to the Services; and (iv)
                                    shall not and is not required to mediate or resolve any dispute or disagreement between Users
                                    and/or the Partners and/or any third Persons.
                                </li>
                                <li>
                                    You agree to abide by the Partner Terms and Conditions imposed by any Partner with whom
                                    you elect to deal, including, but not limited to, payment of all amounts and compliance with the
                                    Partner Terms and Conditions governing the Partner Services. You understand that any
                                    violation of any such Partner Terms and Conditions may result in cancellation/termination of the
                                    Partner Services purchased by you and you being denied access to the applicable Partner
                                    Services, in your forfeiting any monies paid for such Partner Services, and/or in debiting your
                                    account for any costs which may be incurred as a result of such violation. Any changes which
                                    you want to make with respect to a relevant Partner Service (including cancellations and
                                    refunds) or other issues which you may face with respect to any relevant Partner Service
                                    purchased by you should be resolved by you directly with the relevant Partner from whom you
                                    purchased the Partner Service. The Company has no responsibility whatsoever for any
                                    arrangements you make with any Partner as a result of your use of the Services and/or the
                                    Platform. In the event that you encounter any problems with any transaction which you make or
                                    attempt to make via the Services, you acknowledge and agree that you should resolve that
                                    issue directly with the relevant Partner and that your sole remedy in such circumstances,
                                    including any refund, lies with the relevant Partner and not with the Company. The Partner
                                    Terms and Conditions will set out what rights you have against the Partner and will explain their
                                    liability to you in the event of any issues or disputes arising with such Partner. In the event of
                                    a
                                    conflict between these Terms and the Partner Terms and Conditions, these Terms shall prevail.
                                </li>
                                <li>
                                    All features, content, specifications and prices of Partner Services described or depicted on the
                                    Platform are subject to change without notice. Photographs and illustrations and other
                                    promotional material on the Platform are for promotional purposes only and they do not
                                    represent any benefit that will be included in any arrangements made by you.The inclusion of
                                    any Partner Service on the Platform at a particular time does not imply or warrant that such
                                    Partner Service will be available at any time. The Partner Services are subject to availability
                                    and can be withdrawn at any time and from time to time without notice by the relevant Partner.
                                    Our Partners may add, amend or withdraw any Partner Service at any time. Any offers,
                                    discounts and other benefits offered by the Partners are at the sole discretion of the Partners
                                    and are subject to availability and the additional terms and conditions such Partner may have.
                                </li>
                                <li>
                                    Without limiting the foregoing, the Company shall not be liable for any delay, failure, non-
                                    performance or deficiencies in the Services and such delay, failure, non-performance or
                                    deficiency shall not constitute the Company’s failure to meet the requirements detailed in these
                                    Terms, to the extent that any such failure is attributable to: (i) Force Majeure; (ii) User’s acts
                                    or
                                    omissions (including, among other things, violations of law, willful misconduct, negligent acts or
                                    breach of these Terms); (iii) acts or omissions of third parties related to the Users; (iv) acts or
                                    omissions of the Partners, their directors, employees, officers, advisors, sub-contractors,
                                    supplies, vendors, agents and representatives; (v) restrictions/constraints imposed by
                                    Applicable Laws; (vi) a failure by the User to provide the Company and/or the Partner,
                                    immediately, with any information or other material reasonably requested by the Company
                                    and/or the Partner; (vii) any inaccurate or misleading information supplied by the User to the
                                    Company and/or the Partner and upon which the Company and/or the Partner relies; (viii) the
                                    User’s failure to take such corrective action which forms a part of the User’s responsibility, as
                                    may be reasonably requested and identified by the Company and/or the Partner to the User;
                                    and (ix) such other reasons solely attributable to the User and/or the Partner.
                                </li>
                                <li>
                                    Notwithstanding anything contained in these Terms, the Company shall not be required to
                                    provide a Service to the extent the provision thereof would violate or contravene any Applicable
                                    Law.
                                </li>
                                <li>
                                    The Company may, at its sole discretion, introduce new services and modify some or all of the
                                    existing Services. The Company reserves the right to introduce a fee for the Services offered.
                                    The Company also reserves the right, with or without prior notice, to: (i) limit the availability
                                    of or to discontinue any Service; (ii) impose conditions on the use of Services; (iii) refuse to
                                    provide
                                    any User with any Services.
                                </li>
                                <li>
                                    While the Company shall continuously work towards keeping the Platform functional, you
                                    hereby acknowledge that technical failures are a part of any program. We shall take adequate
                                    steps to keep the Platform running and in the event of any unforeseen problems or technical
                                    malfunction of our network, servers, computer systems or equipment, or software on account of
                                    technical problems or traffic congestion on the internet or combination thereof, we will take the
                                    necessary measures to remedy the problem. However, we do not assume any liability
                                    whatsoever in respect of any Loss arising directly or indirectly to you, including but not limited
                                    to
                                    loss of profit, loss of data, or loss of opportunity arising out of such unforeseen events and/or
                                    technical malfunction.
                                </li>
                                <li>
                                    Use of the Services requires a supporting environment, including internet access, compatible
                                    hardware and software, and may also require periodic updates to your browser, mobile
                                    application, hardware, and/or other software. You agree that meeting these requirements is
                                    your sole responsibility and such requirements may change from time to time.
                                </li>
                                <li>
                                    You shall be responsible and liable to the Company for all actions, claims, proceedings and
                                    Losses incurred by the Company arising out of, or in any way connected with, your use of the
                                    Services, the Platform or breach of these Terms.
                                </li>

                            </ol>
                        </li>
                        <li>REGISTRATION
                            <ol>
                                <li>
                                    To sign up for the Services, you might register for an account on the Platform <span>("Account")</span>.
                                    You
                                    must provide accurate and complete information and keep your Account information updated.
                                    You shall not select or use as a username: (i) a name which belongs to another user; (ii) the
                                    name of another person with the intent to impersonate that person; (iii) a name which is subject
                                    to the rights of a person for which you do not have an appropriate authorization; or (iv) a name
                                    that is otherwise offensive, vulgar or obscene. You agree that if you provide any information that
                                    is untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect that
                                    such information is untrue, inaccurate, not current or incomplete, or not in accordance with the
                                    these Terms, we shall have the right to indefinitely suspend or terminate or block your access to
                                    the Platform and Services. Notwithstanding anything contained herein, we may, in our sole
                                    discretion, refuse to offer or suspend the Services to any Person for any reason. You shall: (i)
                                    be responsible for maintaining the confidentiality of your user name and password; (ii) not share
                                    your username and password with third parties; (iii) never publish, distribute or post login
                                    information for your Account; and (iv) notify the Company promptly of any unauthorized use of
                                    your Account and of any loss, theft or disclosure of your password. You are solely responsible
                                    for the activity that occurs on your Account, regardless of whether the activities are undertaken
                                    by you, your employees or a third party (including your contractors or agents), and for keeping
                                    your Account password secure. You shall never use another User’s account or registration
                                    information for the Services without permission. You must notify us immediately of any change
                                    in your eligibility to use the Services (including any changes to or revocation of any licenses
                                    from any state, provincial, territorial or other authorities), breach of security or unauthorized
                                    use
                                    of your Account. You shall have the ability to disable your Account either directly or by making a
                                    request to us. Failure to comply with these requirements shall constitute a breach of these
                                    Terms and shall constitute grounds for immediate termination of your Account and your right to
                                    use the Platform. The Company shall not be liable for any loss or damage as a result of your
                                    failure to provide us with accurate information or to keep your Account secure.
                                </li>
                                <li>
                                    Subject to these Terms, we grant each User of the Services a non-exclusive, revocable, non-
                                    sub-licensable, non-transferable and limited license to use the Platform through the Account,
                                    solely for the purpose of using the Services in terms hereof. You shall not distribute, sell,
                                    license, rent or otherwise exploit your username and password for accessing your Account in
                                    any manner whatsoever.
                                </li>
                                <li>
                                    Certain portions of the Platform and the Services will be visible/available to Users who have not
                                    registered with the Company. Such users, nevertheless, are bound by these Terms.
                                </li>
                                <li>
                                    Failure to comply with the above terms shall constitute a breach of these Terms and shall
                                    constitute grounds for immediate termination of your Account and your right and license to use
                                    the Platform and the Services.
                                </li>
                            </ol>
                        </li>
                        <li>PAYMENTS, BILLINGS AND REFUNDS
                            <ol>
                                <li><span>Membership:</span> Accessing the Platform and opening an Account and use of the Platform is
                                    free
                                    for the Users.
                                </li>
                                <li>
                                    <span>Service Fee:</span> The Company may charge a service fee for availing the Services and such
                                    fee
                                    will be determined by the Company from time to time.
                                </li>
                                <li><span>Pricing</span>
                                    <ol>
                                        <li>
                                            All prices quoted are subject to availability at the time of purchase. Prices are subject
                                            to
                                            change until the completion of the transaction.
                                        </li>
                                        <li>
                                            The Company does not, whether directly or indirectly, influence the prices mentioned by
                                            the Partners for the Partner Services. The pricing shown for the Partner Services are
                                            solely decided by the Partners and the Company is not, shall not and cannot be held
                                            liable for any issues arising out of such pricing. It is hereby expressly provided that any
                                            discounts and/or refunds offered to you pursuant to your use of the Platform is solely
                                            provided by the Partner and in case of any non-compliance pursuant to a transaction,
                                            the Company shall not be held liable for the same.
                                        </li>
                                        <li>
                                            All prices are subject to modification/change from time to time at the sole discretion of
                                            the Partners and shall be effective from the moment such revised prices are posted on
                                            this Platform. It shall be the User’s responsibility to remain informed about the then
                                            current prices for the Partner Services.
                                        </li>
                                        <li>
                                            While the Company strives to provide accurate pricing information, pricing of any
                                            Partner Services as reflected on the Platform may, due to some technical issue,
                                            typographical error, pursuant to erroneous information published by the Partner, or due
                                            to some other reason, be incorrectly reflected and in such an event, the Partner and on
                                            its behalf, the Company may cancel any transaction made by a User for the Partner
                                            Services.
                                        </li>
                                    </ol>
                                </li>
                                <li>Purchase of Partners Services
                                    <ol>
                                        <li>
                                            If you wish to purchase a relevant Partner Services made available via the Platform
                                            (each such purchase, a <span>“Transaction”</span>), you may be asked to provide certain
                                            information relevant to your Transaction. Each Transaction shall be subject to the
                                            Company’s and the Partner’s then-current payment and purchase terms. By submitting
                                            such information, you acknowledge that it may be submitted to third parties for purposes
                                            of facilitating the completion of the Transactions initiated by you or on your behalf, and
                                            to otherwise handle such information in accordance with the Privacy Policy. Verification
                                            of information may be required prior to the acknowledgment or completion of any
                                            Transaction.
                                        </li>
                                        <li>
                                            You acknowledge that the Company is facilitating the Transactions with the Partners
                                            through your use of the Platform or Service. Any purchase you ultimately make with any
                                            Partner will be between you and such Partner, and the Company will have no liability,
                                            and you hereby release the Company from all liability, with respect to such Transaction.
                                            You represent and warrant that all payment information you use, including any credit
                                            card information, will be accurate and up to date. The Company and the Partner
                                            maintains the right to withhold any payment otherwise due to you and to set off such amount
                                            against any amount that you may owe or that is attributable to your use of
                                            inaccurate or fraudulent payment information, and you will remain responsible and liable
                                            for any Losses the Company and/or the Partner incurs to collect such amounts from
                                            you.
                                        </li>
                                    </ol>
                                </li>
                                <li>Payments
                                    <ol>
                                        <li>
                                            All payments shall be made in accordance with the payment options provided by the
                                            relevant Partner. Unless otherwise stated, all prices shall be quoted in Indian Rupees.
                                            You shall be solely responsible for compliance of all Applicable Laws including those in
                                            India for making payments to the relevant Partner.
                                        </li>
                                        <li>
                                            Subject to Applicable Law, the Customer shall be liable for all applicable taxes with
                                            respect to the Partner Services and this shall be in addition to the prices offered by the
                                            Partner.
                                        </li>
                                        <li>
                                            The procedure adopted for payments to the Company is as follows. You will have to
                                            make a payment of the prescribed price in order to confirm your purchase of the
                                            relevant Partner Services. Upon the successful payment of the amount, a receipt
                                            containing the details of the payment will be generated on behalf of the Partner by the
                                            Company. All invoices are denominated in Indian Rupees. For any assistance or
                                            queries, please e-mail us at [●] from your registered email address.
                                        </li>
                                        <li>
                                            You hereby understand, acknowledge and agree that any payment made by you for any
                                            Partner Services to the Company is received by the Company on behalf of the relevant
                                            Partner, and in the event of the completion of a Transaction, the said amount is released
                                            to the Partner through an automated mechanism, in accordance with Applicable Laws.
                                        </li>
                                        <li>
                                            Payments for the Partner Services may also be made through an electronic and
                                            automated collection and remittance service (the <span>“Payment Gateway”</span>) hosted on
                                            the
                                            Platform through payment gateway service providers. The Payment Gateway service is
                                            provided to you in order to facilitate your purchase of a Partner Service. The processing
                                            of payment may be subject to the terms, conditions and privacy policies of the payment
                                            gateway service providers <span>(“Payment Gateway Service Provider”)</span> in addition to
                                            these
                                            Terms. The terms of your payment may also be determined by agreements between you
                                            and the financial institution, card issuer or other provider of your chosen payment
                                            method. By choosing to use the Platform, you agree to pay us all charges at the prices
                                            then in effect for any use of the Services in accordance with the applicable payment
                                            terms. In addition to that, you shall also be liable to pay all charges payable by you to
                                            your bank, financial institution, card issuer or other provider of your chosen payment
                                            method and the Payment Gateway for making the purchase. You represent that you
                                            have the legal right to use any payment method that you use to remit any amount to us.
                                        </li>
                                        <li>
                                            The Company makes no representation of any kind, express or implied, as to the
                                            operation of the Payment Gateway. The Company is not responsible for any errors by
                                            the Payment Gateway Service Provider. The Company reserves the right to correct any
                                            errors or mistake that it makes even if it has already requested or received payment.
                                            The Company assumes no liability whatsoever for any monetary or other damages
                                            suffered by you on account of: (i) access to, use of, or reliance on the Payment
                                            Gateway services; (ii) the delay, failure, interruption or corruption of any data or other
                                            information transmitted in connection with use of the Payment Gateway; (iii) any
                                            interruption or errors in the operation of the Payment Gateway; or (iv) any excess
                                            payment made by you. Further, you expressly agree that your use of the Payment
                                            Gateway is entirely at your own risk. The Company does not collect and store any
                                            payment and other details provided by you for transacting through the Payment
                                            Gateway. You agree, understand and confirm that personal data including without
                                            limitation details relating to debit card/ credit card transmitted over the internet is
                                            susceptible to misuse, theft and/or fraud and that the Company has no control over such
                                            matters. The Company does not represent or guarantee that the use of the Payment
                                            Gateway will not result in theft and/or unauthorized use of data over the internet.
                                        </li>
                                        <li>
                                            The records of the Company and/or the Payment Gateway Service Provider, generated
                                            by the transaction arising out of the use of the Services, including the time the
                                            transaction is recorded, shall be conclusive proof of the genuineness and accuracy of
                                            the transaction. The details provided by you for use of the Payment Gateway Service
                                            Provider will be correct and accurate and you shall not use a debit/credit card net
                                            banking which is not lawfully owned by you. You further agree and undertake to provide
                                            correct and valid debit/credit card/net banking details. In default of the above
                                            conditions,
                                            the Company or the Partner reserves the right to initiate any legal action for recovery of
                                            cost/penalty or any other measures, as it may deem fit.
                                        </li>
                                        <li>
                                            While availing any of the payment method/s available on the Platform, the Company will
                                            not be responsible or assume any liability whatsoever in respect of ay loss or damage
                                            arising directly or indirectly to you.
                                        </li>
                                        <li>
                                            All transactions or deals between a User and a Partner are on a principal- to principal
                                            basis as between them and the Company shall not be liable or responsible for the non-
                                            completion of any deal or transaction or any loss or damage suffered by the Users in
                                            connection with any deals/transactions.
                                        </li>
                                    </ol>
                                </li>
                                <li><span>Refunds:</span> When you perform a Transaction, legal obligations arise and your right to
                                    refund of
                                    money is limited by our terms and policies and by the conditions of each relevant Partner.
                                </li>
                            </ol>
                        </li>
                        <li>CONTENT OF THE PLATFORM
                            <ol>
                                <li>
                                    For the purpose of these Terms, the term “<span>Content</span>” includes, without limitation,
                                    information
                                    (including Travel Information), data, documents, text, scripts, downloads, files, images,
                                    photographs, graphics, videos, predictions, interactive features, communications, algorithms,
                                    software, programs, codes, tools, webcasts, publications, content, resources, advertising
                                    materials, promotional materials, contest materials, publications, pricing information, product
                                    and service updates, customer service updates, feedbacks and written posts and comments
                                    generated, provided or otherwise made accessible on the Platform or through the Services.
                                </li>
                                <li>
                                    The Services shall contain Content specifically provided by us and/or our Partners or licensors
                                    and such Content is protected by copyrights, trademarks, trade secrets or other proprietary and
                                    intellectual property rights and Laws. Your use of the Content shall, at all times, be subject to
                                    these Terms and you shall abide by and maintain all copyright notices, information, and
                                    restrictions contained in any Content accessed through the Services.
                                </li>
                                <li>
                                    While the Company uses reasonable efforts to include accurate and up-to-date information on
                                    the Platform, the Company makes no warranties or representations as to its accuracy,
                                    correctness, completeness and timeliness. The Company does not assume any liability or
                                    responsibility for any errors or omissions in the Content.
                                </li>
                            </ol>
                        </li>
                        <li>USER CONTENT
                            <ol>
                                <li>
                                    All content created, added, uploaded, submitted, distributed, communicated or posted on the
                                    Platform by Users (collectively <span>"User Content"</span>), whether publicly posted or privately
                                    transmitted, is the sole responsibility of the User/Person who originated such User Content. The
                                    Platform permits the submission of User Content and the hosting, sharing, and/or publishing of
                                    such User Content. Notwithstanding anything contained herein, the User shall be solely
                                    responsible for the User’s own User Content and the consequences of posting or publishing
                                    them. You represent that all User Content provided by you is accurate, complete, up-to-date,
                                    and in compliance with all applicable laws, rules and regulations.
                                </li>
                                <li>
                                    You acknowledge that all Content and User Content, accessed by you using the Services, is at
                                    your own risk and you will be solely responsible for any damage or loss to you or any other
                                    party resulting therefrom. We do not guarantee that any Content, or User Content you access
                                    on the Platform or through the Services is or will continue to be accurate. The Company does
                                    not and will not claim any ownership over or rights to the User Content, except to the extent
                                    stated herein.
                                </li>
                                <li>
                                    In connection with the User Content, the User affirms, represents, and/or warrants that: (i) the
                                    User owns or has the necessary licenses, rights, consents, permissions and authorizations to
                                    use and authorise the Company to use, all User Content, including to enable the Company to
                                    include and use the User Content for the purposes and in the manner contemplated by these
                                    Terms; (ii) the User has the written consent, authorisation, release and/or permission of each
                                    and every identifiable individual person in the User Content to use the name or likeness of each
                                    and every such identifiable individual person to enable inclusion and use of the User Content in
                                    the manner contemplated by these Terms of Use; and (iii) the Company will not be subjected to
                                    any claim, actions, liabilities, loss and damage as a result of its use of the User Content.
                                </li>
                                <li>
                                    By submitting any User Content to the Platform, you hereby do and shall grant us a worldwide,
                                    non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sub-licensable and transferable
                                    license to use, aggregate, reproduce, distribute, prepare derivative works of, display, perform,
                                    and otherwise fully exploit such User Content in connection with the Platform, the Services and
                                    our (and our successors' and assigns') businesses, including without limitation for promoting
                                    and redistributing, part or all of the Platform or the Services (and derivative works thereof) in
                                    any media formats and through any media channels (including, without limitation, third party
                                    websites and feeds), and including after your termination of your arrangement with us or the
                                    Services.
                                </li>
                                <li>
                                    By submitting any User Content to the Platform, you also hereby do and shall grant each User
                                    (only to the extent such User Content is accessible to other Users) a non-exclusive and
                                    perpetual license to access any of your User Content that is available to such User on the
                                    Platform, and to use, reproduce, distribute, prepare derivative works of, display and perform
                                    such User Content, including after your termination of your arrangement with us or the
                                    Services.
                                </li>
                                <li>
                                    For clarity, the foregoing licenses granted to us and our Users does not affect your other
                                    ownership or license rights in your User Content, including the right to grant additional licenses
                                    to your User Content, unless otherwise agreed in writing. You represent and warrant that you
                                    have all rights to grant such licenses to us without infringement or violation of any third party
                                    rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks,
                                    contractual rights, or any other intellectual property or proprietary rights.
                                </li>
                                <li>
                                    The Company: (i) cannot control the User Content and other information provided by Users
                                    which is made available on the Platform; (ii) does not research, endorse, validate or certify the
                                    User Content and any other information submitted/uploaded by Users on the Platform nor
                                    assumes that all User Content and information will be complete, accurate, truthful, trustworthy
                                    and reliable; and (iii) expressly disclaims any and all liability in connection with User Content.
                                </li>
                                <li>
                                    As mandated by Regulation 3(2) of the Information Technology (Intermediaries Guidelines)
                                    Rules, 2011, the Company hereby informs Users that they are not permitted to host, display,
                                    upload, modify, publish, transmit, update or share any information that:
                                    <ol>
                                        <li>
                                            belongs to another person and to which the User does not have any right to;
                                        </li>
                                        <li>
                                            is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic,
                                            pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically
                                            objectionable, disparaging, relating or encouraging money laundering or gambling, or
                                            otherwise unlawful in any manner whatever;
                                        </li>
                                        <li>
                                            harm minors in any way;
                                        </li>
                                        <li>
                                            infringes any patent, trademark, copyright or other proprietary rights;
                                        </li>
                                        <li>
                                            violates any law for the time being in force;
                                        </li>
                                        <li>
                                            deceives or misleads the addressee about the origin of such messages or
                                            communicates any information which is grossly offensive or menacing in nature;
                                        </li>
                                        <li>
                                            impersonate another person;
                                        </li>
                                        <li>
                                            contains software viruses or any other computer code, files or programs designed to
                                            interrupt, destroy or limit the functionality of any computer resource; and
                                        </li>
                                        <li>
                                            threatens the unity, integrity, defence, security or sovereignty of India, friendly
                                            relations
                                            with foreign states, or public order or causes incitement to the commission of any
                                            cognisable offence or prevents investigation of any offence or is insulting any other
                                            nation.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>MANDATORY RULES OF CONDUCT
                            <ol>
                                <li>
                                    As a condition of use of the Platform and the Services, you promise and undertake not to use
                                    the Services for any purpose that is prohibited by these Terms. You are responsible for all of
                                    your activities in connection with the Services and the activities of any Person who uses your
                                    Account or who uses your computers, systems, mobiles, machines, communication devices,
                                    network and others’ applications and resources.
                                </li>
                                <li>
                                    By using the Platform and the Services, you agree specifically that: (i) you will use your own
                                    independent and informed judgement before making any decision to buy any Partner Services
                                    and the Company is not providing you any advice or making any recommendations with respect
                                    to any Partner Services or a deal or transaction; (ii) you do not expect the Company to, and
                                    understand that the Company does not, evaluate, confirm, verify or endorse any content,
                                    whether viewed on the Platform or otherwise or otherwise conduct its own independent due
                                    diligence; (iii) you are not relying on the Content, or any data and information that appears on
                                    the Platform to make any decision related to your purchase of the Partner Services; (iv) you are
                                    solely responsible for complying with any Applicable Law regarding any transaction carried out
                                    with the Partners; (v) you will obtain such professional advice as is appropriate to protect your
                                    interest; (vi) there is a great risk of incurring losses and damages as a result of any transaction
                                    undertaken through the Platform and that you are willing to undertake this risk at your own will;
                                    and (vii) any decision taken by you pursuant to the use of the Services are at your own will and
                                    risk and you are able to bear the loss after completely understanding the risks involved in such
                                    transactions.
                                </li>
                                <li>
                                    You agree that you shall not (directly or indirectly) submit, upload, provide, transmit,
                                    distribute,
                                    post, store, link, or otherwise share content, information, data, software, and/or materials on the
                                    Platform or through the Services that: (i) you do not have the right, authority or license to make
                                    available, whether under Law, contract or otherwise; (ii) is unlawful, threating, abusive,
                                    harassing, defamatory, libelous, deceptive, fraudulent, tortious, offensive, profane, contains or
                                    depicts pornography, or is otherwise inappropriate as determined by us, in our sole discretion;
                                    (iii) is false, misleading, untruthful or inaccurate; (iv) is invasive of another’s privacy; (v)
                                    violates
                                    the publicity or proprietary or intellectual property rights of third parties; (vi) impersonates
                                    any
                                    person or entity, including any of our employees, agents, representatives, sub-contractors or
                                    Partners or falsely states or otherwise misrepresents your affiliation with any Person or
                                    expresses or implies that the Company endorses any statement you make; (vii) includes
                                    anyone’s identification documents or sensitive financial information or any personal information
                                    about another individual or any information that may be used to track, contact or impersonate
                                    that individual; (viii) discloses or provides information protected under any Law, agreement or
                                    fiduciary relationship, including but not limited to proprietary or confidential information of
                                    others, insider information or material, or non-public information; (ix) constitutes unauthorized
                                    or
                                    unsolicited advertising; (x) violates any Law; (xi) would give rise to criminal or civil liability
                                    (under tort or otherwise); (xii) that encourages conduct that constitute an offense or that
                                    encourages or provides instructional information about illegal activities; (xiii) has the potential
                                    to
                                    create liability for us; (xiv) could cause us to lose, in whole or in part, our relationship with
                                    our
                                    Users, Partners, clients, business partners, licensors, services providers or other suppliers; (xv)
                                    interferes with or disrupts the operation of the Platform/Services or the servers or networks
                                    used to make the Platform/Services available, or violate any requirements, procedures, policies or
                                    regulations of such networks; and (xvi) threatens the unity, integrity, defense, security or
                                    sovereignty of any country, friendly relations with foreign states, or public order or causes
                                    incitement to the commission of any cognizable offence or prevents investigation of any offence
                                    or is insulting any other nation.
                                </li>
                                <li>
                                    You shall not (directly or indirectly): (i) disclose any sensitive, proprietary or confidential
                                    information, about yourself or anyone else or knowingly disseminate information that you know,
                                    reasonably ought to know, to be confidential in nature, in violation of any duty of confidentiality
                                    which you are subject to; (ii) misrepresent the source of your content; (iii) use the Platform or
                                    the Service in violation of any Applicable Law or otherwise in violation of any Applicable Law
                                    which you may be subject to; (iv) use the Service in a manner that would violate the lawful
                                    privacy rights of any Person or to publish or republish defamatory or libelous statements, or to
                                    harass or embarrass any person; (v) use the Service in a manner which could cause loss,
                                    damage or personal injury to any Person; (vi) use the Service for any fraudulent or unlawful
                                    purpose or for the promotion of illegal activities; (vii) use the Services if you are a competitor
                                    of
                                    the Company or for reasons that are in competition with the Company; (viii) use the Services,
                                    the Content or its features and services in any manner that could interrupt, damage, disable,
                                    overburden or impair the Services or interfere with any other Persons use and enjoyment of the
                                    Services; (ix) bypass, circumvent or attempt to bypass or circumvent any measures we may
                                    use to prevent or restrict access to the Services (or other computer systems, resources or
                                    networks connected to the Services); (x) run any form of auto-responder or “spam” on the
                                    Services; (xi) use manual or automated software, device, or other processes to “crawl” or
                                    “spider” any page of the Platform; (xii) obtain or attempt to gain unauthorized access to other
                                    computer systems, resources, materials, information or any services available on or through the
                                    Platform through any means, including through means not intentionally made publically
                                    available or provided for through the Platform; (xiii) take any action that imposes or may impose
                                    (as determined by us in our sole discretion) an unreasonable or disproportionately large load on
                                    our (or our third party service providers’) infrastructure; (xiv) interfere or attempt to interfere
                                    with
                                    the proper working of the Platform and Services or any activities conducted on the Platform;
                                    (xv) use the Services to distribute, receive communications or data gleaned from, or execute
                                    any action directed by any type of injuries code, including but not limited to: (a) trojans, (b)
                                    key
                                    loggers, (c) viruses, (d) malware, (e) botnets, (f) denial of service attacks,(g) flood or mail
                                    bombs,(h) logic bombs, or (i) other actions which the Company reserves the sole right to
                                    determine to be malicious in intent; (xvi) make improper use of our Services or submit false
                                    reports of abuse or misconduct; (xvii) disparage, tarnish or otherwise harm the Company,
                                    including the Platform and the Services; (xviii) offer, attempt to offer, trade or attempt to trade
                                    in
                                    any item, the dealing of which is prohibited or restricted in any manner under the provisions of
                                    any applicable law, rule, regulation or guideline for the time being in force; and (xix) use the
                                    Platform or the Service in violation of these Terms or otherwise take any action in violation of
                                    our guidelines and policies.
                                </li>
                                <li>
                                    In using the Services, you must comply with, and refrain from violations of, any right of any
                                    other Persons, Law or contractual duty, including without limitation Laws forbidding: (i)
                                    distribution of child pornography;(ii) forgery, identity theft, misdirection or interference with
                                    electronic communications; (iii) invasion of privacy; (iv) unlawful sending of commercial
                                    electronic message or other marketing or electronic communications; (v) securities violations,
                                    wire fraud, money laundering, or terrorist activities; or (vi) false advertising, propagating or
                                    profiting from fraudulent and unfair schemes.
                                </li>
                                <li>
                                    You shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or
                                    otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the
                                    Services (including without limitation any application); (ii) modify, translate, or otherwise
                                    create
                                    derivative works of any part of the Services; and/or (iii) copy, rent, lease, distribute, or
                                    otherwise
                                    transfer any of the rights that you receive hereunder.
                                </li>
                                <li>
                                    You shall abide by all Applicable Laws with respect to the use of the Platform and Services.
                                </li>
                                <li>
                                    Any activity that you are prohibited from performing under these Terms is equally prohibited to
                                    anyone using your Account, computers, systems, mobiles, machines, communication devices,
                                    network and others’ applications and resources to access the Services.
                                </li>
                                <li>
                                    You shall: (i) be fully responsible for the acts of others utilizing your access to the Services,
                                    and
                                    will be held responsible for violations of the Services by your sub-Users or Persons who gain
                                    access to the Services using your Account, computers, systems, mobiles, machines,
                                    communication devices, network and others applications and resources; (ii) utilize proper
                                    security protocols, such as setting strong passwords and access control mechanisms,
                                    safeguarding access to all logins and passwords, and verifying the trustworthiness of the
                                    Persons who are entrusted with access to your Account, computers, systems, mobiles,
                                    machines, communication devices, network and others applications and resources and
                                    information; (iii) notify all Persons who receive access to the Services of the provisions of these
                                    Terms, and inform them that these Terms are binding upon them; and (iv) notify the Company if
                                    and when you learn of security breaches regarding the Services, and shall aid in any
                                    investigation or legal action that is taken by authorities and/or the Company to cure the security
                                    breach.
                                </li>
                                <li>
                                    You shall assume full legal responsibility for any access and use of the Services, and in the
                                    event of any breach of this duty resulting in legal claim against the Company, you shall defend,
                                    indemnify and hold the Company harmless from all claims and damages arising therefrom.
                                </li>
                                <li>
                                    We also reserve the right to access, read, preserve, and disclose any information as we
                                    reasonably believe is necessary to: (i) satisfy any Applicable Law, legal process or
                                    governmental request; (ii) enforce these Terms, including investigation of potential violation
                                    hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues; (iv)
                                    respond to your support request; and (v) protect the rights, property or safety of us, our Users
                                    and the public.
                                </li>
                                <li>
                                    You are solely responsible for any reliance by you on the Services or other use you make of the
                                    Services. Comments, suggestion, feedback, ideas, material or other information sent or
                                    transmitted to the Company, either through the Platform, email or otherwise
                                    (collectively <span>“Feedback”</span>), shall be deemed to be and treated as non-proprietary and
                                    non-
                                    confidential. Subject to the conditions described in the Company’s Privacy Policy, the Company
                                    shall have no obligation of any kind with respect to such Feedback and shall be free to use and
                                    distribute the Feedback to others without limitation, including for developing and marketing the
                                    Services. The Company reserves the right to publish or use the Feedback for promotional or
                                    other purpose without any further permission, notice or payment of any kind to the User. All
                                    such Feedback will be the property of the Company. The Company reserves the right to copy,
                                    use, reproduce, modify, adapt, translate, publish, license, distribute, sell or assign the Feedback
                                    in any way as it deems fit, including but not limited to copying in whole or in part, creating
                                    derivative works from, distributing and displaying any Feedback in any form, media, or
                                    technology, whether now known or hereafter developed, alone or as part of other works, or
                                    using the Feedback within or in connection with the Company’s products or services. If you
                                    provide a Feedback, you represent and warrant that you own or otherwise control the rights to
                                    the Feedback. You further represent and warrant that such Feedback does not constitute or
                                    contain software viruses, commercial solicitation, chain letters, mass mailings, or any form of
                                    “spam”. You shall not use a false email, address, impersonate any Person, or otherwise
                                    mislead the Company as to the origin of any Feedback. You agree to indemnify the Company
                                    for all claims arising from any claim to any rights in any Feedback or any damages arising from
                                    any Feedback.
                                </li>
                                <li>
                                    The enumeration of violations in this Paragraph 10 is not meant to be exclusive, and the
                                    Company has and will exercise its authority to take any action which is necessary to protect the
                                    Services, Users, Partners and third parties from acts that would be inimical to the purpose of
                                    this Paragraph.
                                </li>
                            </ol>
                        </li>
                        <li>PRIVACY
                            <ol>
                                <li>We view protection of your privacy as a very important principle. We understand clearly that
                                    you and your personal information is one of our most important assets. Our Privacy Policy
                                    governs our collection, use and disclosure of your personal information and is incorporated into
                                    and forms an integral part of these Terms. Please read it carefully. It describes what information
                                    we collect from you and when, how and why we collect information from you, whom we share
                                    your information with and when and how you can opt-out. This is important information. By
                                    accessing the Platform or using our Services, you specifically consent to our Privacy Policy.
                                </li>
                            </ol>
                        </li>
                        <li>CONFIDENTIALITY
                            <ol>
                                <li>Each User shall use commercially reasonable efforts to keep confidential, any and all data and
                                    information about the Company <span>(“Confidential Information”)</span>, whether written, oral or
                                    visual,
                                    disclosed to it by the Company or which comes into its possession or knowledge, from time to
                                    time. You shall not disclose the Confidential Information to any others, except as may be
                                    required by law or as may be required to be disclosed on a strictly "need-to-know" basis for
                                    implementing the terms hereof.
                                </li>
                            </ol>
                        </li>
                        <li>INTELLECTUAL PROPERTY RIGHTS AND OWNERSHIP
                            <ol>
                                <li>
                                    The Company’s Technology, the Platform and the Company’s Trade Secrets (collectively, the
                                    <span>“Company Intellectual Property”</span>) constitute valuable trade secrets of the Company. All
                                    worldwide intellectual property rights and other proprietary rights in and to the Company
                                    Intellectual Property and all improvements and developments in connection therewith shall be
                                    and remain the exclusive property of the Company. The Users shall not, either directly or
                                    indirectly, infringe, misappropriate or violate the Company Intellectual Property in any manner
                                    whatsoever.
                                </li>
                                <li>
                                    The Platform and all information and Content available on the Platform and its “look and feel”,
                                    including but not limited to the Company’s Marks, copyrighted works, text, graphics, logos,
                                    button icons, images, audio/video clips, data compilations and software, and the compilation
                                    and organization thereof is the property of the Company, its affiliates, partners or licensors, or
                                    is
                                    used with consent of the owner and is protected by Laws, including Laws governing, copyrights,
                                    trademarks and other intellectual property. The reproduction and use of any of these by you is
                                    prohibited unless specific written permission is provided by us. Any unauthorized use shall
                                    violate copyright laws, trademark laws and other applicable intellectual property laws.
                                </li>
                                <li>
                                    The Company and the Company’s Marks referenced in the Platform are the intellectual property
                                    rights of the Company. Any unauthorized use of the same is strictly prohibited and all rights in
                                    same are reserved by the Company. No use of any the Company’s Marks may be made by any
                                    third party without the express written consent of the Company.
                                </li>
                                <li>
                                    You agree not to engage in the use, copying, or distribution of any of the Content other than
                                    expressly permitted herein, including any use, copying, or distribution of Content obtained
                                    through the Platform for any commercial purposes. If you download or print any Content for
                                    personal use, you must retain all copyright and other proprietary notice contained therein.
                                </li>
                                <li>
                                    Subject to these Terms, we grant each User a non-exclusive, revocable, non-sub-licensable,
                                    non-transferable and limited license to access the Platform solely for the purpose availing the
                                    Services.
                                </li>
                                <li>
                                    Nothing contained on this Platform should be construed as granting, by implication or
                                    otherwise, any right, license or title to any of the Company’s intellectual property and the
                                    Company’s Marks, without the advance written permission of the Company or such third party
                                    as may be appropriate. All rights are expressly reserved and retained by the Company. Your
                                    misuse of any of the Company Intellectual Property, the Company’s Marks displayed on the
                                    Platform, or any other Content on the Platform, except as provided in these Terms, is strictly
                                    prohibited. You are also advised that the Company considers its intellectual property to be
                                    among its most valuable assets and will aggressively enforce its intellectual property rights to
                                    the fullest extent provided under Law.
                                </li>
                            </ol>
                        </li>
                        <li>DISCLAIMER
                            <ol>
                                <li>
                                    You acknowledge that we have no duty to take any action regarding: (i) which Users gain
                                    access to the Services; (ii) what Content you access via the Services; or (iii) how you may
                                    interpret or use the Content.
                                </li>
                                <li>
                                    The Platform, the Services, the Content, including without limitation, any information delivered
                                    as part of the foregoing are provided on an “as is” and “as available” basis, without warranty of
                                    any kind, express or implied, whether by statute, common law, custom, usage or otherwise,
                                    including, but not limited to performance, security, integration, quiet enjoyment, satisfactory
                                    quality and implied warranties of title, non-infringement, merchantability and fitness for a
                                    particular purpose as well as all warranties arising by usage of trade, course of dealing, or
                                    course of performance. Without limiting the foregoing, the Company makes no warranty that:
                                    (i) Platform, the Services, the Content, including without limitation any materials and information
                                    delivered as part of the foregoing: (a) will meet your requirements; (b) will be accurate,
                                    uninterrupted, timely, secure, or error-free; (ii) the results that may be obtained from the use
                                    thereof will be effective, accurate, or reliable; (iii) the quality of the Partner Services
                                    purchased
                                    or accessible by you will meet your expectations; (iv) any errors or defects in the foregoing will
                                    be corrected; and (v) is free of viruses or other harmful components.
                                </li>
                                <li>
                                    You acknowledge and agree that: (i) the Company does not control, endorse, or accept
                                    responsibility for any products, materials or services offered by the Partners and other third
                                    parties on the Platform, including the Partner Services; (ii) the Company makes no
                                    representations or warranties about the Partners and other third parties on the Platform,
                                    including the Partner Services and any products, materials or services offered by such third
                                    parties; (iii) any dealings you may have with the Partners and such other third parties are at
                                    your own risk; and (iv) the Company shall not be liable or responsible for any product, materials
                                    or services offered by the Partners and other third parties.
                                </li>
                                <li>
                                    The Company disclaims any and all liability with respect to any Losses, personal injury or other
                                    harm that may be caused by your reliance on any advice, suggestions, recommendations or
                                    other information provided on the Platform.
                                </li>
                                <li>
                                    The Company excludes all representations and warranties relating to the data, information and
                                    contents provided by the Partners, including in relation to any inaccuracies or omissions therein
                                    including with respect to the quality, location, pricing or availability. No content, data,
                                    information or materials provided by the Partners on the Platform should be considered as
                                    complete or accurate, nor should it be relied upon for any purpose or means. Without prejudice
                                    to the foregoing, we specifically disclaim all liability for any reviews, comments, ratings,
                                    recommendations and opinions provided by Users of the Partner Services or products and
                                    services provided by other third parties.
                                </li>
                                <li>
                                    By providing information relevant to Travel to any particular destination, whether such
                                    information is posted by us, our Users of the Services, or Partners or other third parties, we do
                                    not represent or warrant that Travel to such destinations is advisable or without risk. Your use of
                                    the Service and your reliance or non-reliance on any such information is done entirely at your
                                    own discretion and at your sole risk. We make no representations about the suitability of the
                                    information, products, and services contained on the Service for any purpose, and the inclusion
                                    or offering of any products or services on the Service does not constitute any endorsement or
                                    recommendation of such products or services by us.
                                </li>
                                <li>
                                    Since the Company acts only as an intermediary between you and the Partners, it shall not
                                    have any liability whatsoever for any Transactions or other arrangements between you and the
                                    relevant Partners, including with respect to standards of the services provided by the Partners.
                                    Also, the Company is not the service provider and cannot therefore control or prevent changes
                                    in the published descriptions which are based upon information provided by the Partners.
                                </li>
                                <li>
                                    The Company does not endorse any advertiser on its Platform in any manner. The Users are
                                    requested to verify the accuracy of all information on their own before undertaking any reliance
                                    on such information.
                                </li>

                            </ol>
                        </li>
                        <li>INDEMNIFICATION
                            <ol>
                                <li>
                                    You shall indemnify, defend and hold harmless us, our affiliates, holding companies,
                                    subsidiaries, affiliates and any related companies, licensors and partners, and each of our and
                                    their respective officers, directors, employees, consultants, agents, representatives,
                                    contractors, content providers, vendors and suppliers from all Losses brought against or
                                    suffered by any of them resulting from, arising out of or relating to your (or any third party
                                    using
                                    your identity or your Account, computers, systems, mobiles, machines, communication devices,
                                    network and others applications and resources): (i) use or misuse of, or access to, the Platform,
                                    the Services, Content, or otherwise from your User Content; (ii) violation or breach of these
                                    Terms; (iii) violation or breach of any Applicable Law, whether or not referenced herein; (iv)
                                    violation of any rights of any third party; and (v) any dealings between you and the Partners and
                                    any third parties. We reserve the right to assume the exclusive defense and control of any
                                    matter otherwise subject to indemnification by you, in which event you will assist and cooperate
                                    with us in asserting any available defenses.
                                </li>
                            </ol>
                        </li>
                        <li>LIMITATION OF LIABILITY
                            <ol>
                                <li>
                                    The Users specifically acknowledge that the Services are provided hereunder with the
                                    expectation that the Company shall not assume any financial or operational risks of the Users.
                                    Accordingly, in no event shall the Company assume any financial or operational risks of the
                                    Company.
                                </li>
                                <li>
                                    The Users further acknowledge and agree that every business or other decision taken by them
                                    pursuant to the transactions contemplated hereunder represents an assumption of risk and that
                                    the Company does not and shall not underwrite or assume the Users risk in any manner.
                                </li>
                                <li>
                                    The Company shall not be held liable for any liabilities suffered by the Users or any third party
                                    relating to or arising out of: (i) a failure by the Users to adhere to these Terms; (ii) negligent
                                    acts of the Users; and/or (iii) breach by the Users of any Applicable Law.
                                </li>
                                <li>
                                    The Users specifically acknowledge and agree that the Company is not involved in transactions
                                    between the Partners (or other third parties listed on the Platform) and the Users and if a
                                    dispute arises between them, the Users shall, and shall ensure that the Partners (or other third
                                    parties listed on the Platform) shall, forever release the Company, its affiliates, directors,
                                    officers, employees, consultants, sub-contractors, agents and representatives from all claims,
                                    demands, and damages (actual and consequential) of every kind and nature, known and
                                    unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way
                                    connected with such disputes. The Users shall ensure that the Company is not made a party to
                                    the disputes between the Users and the Partners (or other third parties listed on the Platform).
                                    The Users shall duly indemnify the Company for Losses suffered by the Company arising from
                                    a dispute between the Users and the Partners (or other third parties listed on the Platform).
                                </li>
                                <li>
                                    In no event shall we, our affiliates, holding companies, subsidiaries, affiliates and any related
                                    companies, licensors and partners, and each of our and their respective officers, directors,
                                    employees, consultants, agents, representatives, contractors, content providers, vendors and
                                    suppliers be liable under contract, tort, strict liability, negligence or any other legal or
                                    equitable
                                    theory with respect to the Services: (i) for any lost profits or other economic losses, loss of
                                    data
                                    and other programs, loss of opportunity, business interruption, cost of procurement of substitute
                                    goods or services, or special, indirect, incidental, punitive, compensatory or consequential
                                    damage of any kind whatsoever; (ii) for any bugs, viruses, trojan horses, or the like (regardless
                                    of the sources of origination), or (iii) for any direct damages in excess of the fees paid to us
                                    for the Services, even if the Company has been advised of, knew, or should have known, the
                                    possibility thereof. You acknowledge that the fee paid by you reflects the allocation of risk set
                                    forth in these Terms and that the Company would not enter into this agreement and Terms
                                    without these limitations. You hereby waive any and all claims against the Company arising out
                                    of your use of the Platform and the Services, or any conduct of the Company’s Personnel. Your
                                    sole and exclusive right and remedy in case of dissatisfaction with the Services/Company
                                    Personnel or any other grievance shall be your termination and discontinuation of access to or
                                    use of the Services.
                                </li>
                                <li>
                                    In no event shall we, our affiliates, holding companies, subsidiaries, affiliates and any related
                                    companies, licensors and partners, and each of our and their respective officers, directors,
                                    employees, consultants, agents, representatives, contractors, content providers, vendors and
                                    suppliers be liable any bodily injury, loss of life, loss of property or any other accident that
                                    arise
                                    out of or is in any way connected with any use of the Platform, the Services or the Content.
                                </li>
                                <li>
                                    In addition, you agree that the Company is not responsible for any data compiled by our
                                    Services and that the Company shall not be liable, in any manner, as a result of your exposure
                                    to any defamatory, libelous, threatening, unlawfully harassing, obscene or otherwise unlawful
                                    content or data. In no event shall the Company, or any third party provider of any component of
                                    the Services or of any information delivered as part of the Services, be liable to you and/or any
                                    party for any Losses and damages of any kind, including but not limited to direct, indirect,
                                    special, exemplary, punitive, consequential or similar damages.
                                </li>
                                <li>
                                    The Company shall not be liable for Losses and damages resulting from the use of (or the
                                    inability to use) electronic means of communication with the Platform, including, but not limited
                                    to damages resulting from failure or delay in delivery of electronic communications, interception
                                    or manipulation of electronic communications by third parties or by computer programs used for
                                    electronic communications and transmission of viruses.
                                </li>
                            </ol>
                        </li>
                        <li>INFRINGEMENT COMPLAINTS
                            <ol>
                                <li>
                                    You shall not use the services in violation of the copyrights, trademarks, patents, trade secrets
                                    or other intellectual property rights of third parties, nor shall you utilize the Services to
                                    publish
                                    such materials in a manner that would expose them to public view in violation of Law. The
                                    Applicable Laws governing intellectual property will apply to issues of copyrights violations. The
                                    Company will, in appropriate circumstance, terminate access to the Platform/Services of
                                    violators. If a third party believes that you have violated their intellectual property rights and
                                    notifies us, we will forthwith take all necessary action, including but not limited to immediately
                                    terminating your access to the Platform/Services.
                                </li>
                                <li>
                                    We respect the intellectual property rights of others and we prohibit Users from posting on the
                                    Platform any content that violates another party’s intellectual property rights. Applicable Laws
                                    provides a complaint procedure for copyright owners who believe that certain material on the
                                    Platform infringes their rights under applicable intellectual property Law. If you believe that
                                    your
                                    work has been improperly copied and posted on the Platform, please provide us with the
                                    following information: (i) name, address, telephone number, email address and an electronic or
                                    physical signature of the copyright owner or of the Person authorized to act on his/ her behalf;
                                    (ii) a description of the copyrighted work that you claim has been infringed; (iii) a description
                                    of
                                    where on the Platform the material that you claim is infringing, is located; (iv) a written
                                    statement that you have a good faith belief that the disputed use is not authorized by the
                                    copyright owner, its agent, or the law; (v) a statement by you, that the above information in your
                                    notice is accurate and that you are the copyright owner or authorized to act on the copyright
                                    owner’s behalf; and (vi) a declaration given by you that the owner, licensee or such other
                                    authorised person shall bring legal action against such breach, in case of non-compliance by
                                    the breaching party, within the timeline prescribed under the Applicable Law. These
                                    requirements must be followed to give the Company legally sufficient notice of infringement.
                                    You must send copyright infringement complaints to [●].
                                </li>
                            </ol>
                        </li>
                        <li>NON-CIRCUMVENTION
                            <ol>
                                <li>
                                    You shall not, directly or indirectly, contact, deal with or otherwise become involved with (or
                                    enter into any transaction with) any of the officers, directors, employees, consultants, agents,
                                    representatives or sub-contractors of the Company (collectively <span>“Company’s Personnel”)</span>
                                    in
                                    connection with availing the Services for the purpose of avoiding the payment, to the Company,
                                    of fees, charges or otherwise, without the specific written approval of the Company. All
                                    communications with respect to the Services shall be submitted directly to the Company
                                    through the means and modes provided on the Platform and in accordance with the provisions
                                    of these Terms.
                                </li>
                            </ol>
                        </li>
                        <li>THIRD PARTY SERVICES
                            <ol>
                                <li>
                                    The Services may permit you to link to other websites, services or resources on the internet,
                                    and other websites, services or resources, may contain links to the Services. The use of any
                                    website which are controlled, owned or operated by third parties is governed by the terms and
                                    conditions of use and privacy policies for those websites, and not by these Terms. When you
                                    access third party resources on the internet, you do so at your own risk. These other resources
                                    are not under our control, and you acknowledge that we are not responsible or liable for the
                                    content, functions, accuracy, legality, appropriateness or any other aspect of such website or
                                    resources. The inclusion of any such link does not imply our endorsement or any association
                                    between us and their operators. You further acknowledge and agree that we shall not be
                                    responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
                                    caused by or in connection with the use of or reliance on any such content, goods or services
                                    available on or through any such website or resources. It is your responsibility to protect your
                                    systems from items such as viruses, worms, trojan horses and other items of a destructive
                                    nature. The Company expressly disclaims any liability arising in connection with your use
                                    and/or viewing of any websites or other material associated with third party links that may
                                    appear on the Platform. You hereby agree to hold the Company harmless from any liability that
                                    may result from the use of third party links that may appear on the Platform.
                                </li>
                            </ol>
                        </li>
                        <li>NEWSLETTER SUBSCRIBERS
                            <ol>
                                <li>
                                    The Company may send email newsletter to Users who enter their email address in the
                                    “subscribe to our newsletter” box available on the Platform. The newsletter will be sent out
                                    monthly (or at such other intervals, as may be decided by the Company from time to time) to
                                    the advised e-mail address and contain the latest Travel deals, news and promotional material.
                                    On occasion we may send additional newsletters to alert Users of special offers or limited
                                    release specials. Should any recipient of our newsletter wish to unsubscribe they can do so by
                                    clicking on unsubscribe at the bottom of the home page, emailing us at [●].
                                </li>
                            </ol>
                        </li>
                        <li>TERMINATION
                            <ol>
                                <li>The Company reserves the right, in its sole discretion, to terminate your access to all or any
                                    part of the Services at any time, with or without notice, effective immediately, including but not
                                    limited to as a result of your violation of any of these Terms.
                                </li>
                                <li>
                                    Any such termination may result in the forfeiture and destruction of information associated with
                                    your Account.
                                </li>
                                <li>
                                    Upon termination of your access to the Platform or Services for any reason, you shall destroy
                                    and remove from all computers, devices, equipment and other storage media all copies of any
                                    intellectual property owned by the Company that you acquired through the Services.
                                </li>
                                <li>
                                    Except as otherwise set forth hereunder, any and all fees (if any) paid hereunder are non-
                                    refundable and any and all fees owed to the Company before such termination shall become
                                    immediately due and payable, including any liabilities that may have been incurred prior to
                                    termination such as, the Company’s costs for collection (including attorneys’ fees) of any such
                                    charges or other liabilities. Upon termination, any and all rights granted to the User will
                                    immediately be terminated, and the User shall promptly discontinue all use of the Services.
                                </li>
                                <li>
                                    If you wish to terminate your Account, you may do so by following the instructions on the
                                    Platform or through the Services or writing to us at [●].
                                </li>
                                <li>
                                    All provisions of these Terms which, by their nature should survive termination shall survive
                                    termination, including, without limitation, the provisions governing licenses to the User Content,
                                    ownership of intellectual property, warranty disclaimers, indemnity and limitation of liability.
                                </li>
                            </ol>
                        </li>
                        <li>GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION
                            <ol>
                                <li>
                                    These Terms will be governed by the laws of India. Further, the courts of Bengaluru, India shall
                                    have exclusive jurisdiction for all matters arising out of or in connection with these Terms and
                                    you agree to irrevocably submit to the exclusive jurisdiction of such courts.
                                </li>
                                <li>
                                    The concerned parties shall amicably resolve any and all disputes arising out of or in
                                    connection with these Terms, failing which the disputes shall be settled in accordance with
                                    provisions of the Arbitration and Conciliation Act, 1996. The parties will refer the dispute to a
                                    sole arbitrator to be appointed by the Company. Any arbitral award shall be final and binding on
                                    the parties. The seat of the arbitration shall be Bengaluru, India. The language of arbitration
                                    shall be English.
                                </li>
                            </ol>
                        </li>
                        <li>MISCELLANEOUS PROVISIONS
                            <ol>
                                <li><span>Force Majeure:</span> The Company shall not be liable to the Users or any other Persons or be
                                    deemed to be in breach of these Terms by reason of any delay or deficiency in performing, or
                                    any failure to perform, any of its obligations in relation to the Services or other obligations
                                    hereunder, if the delay or deficiency or failure was due to Force Majeure. For the purposes of
                                    these Terms, <span>“Force Majeure”</span> shall mean any event beyond the reasonable control of the
                                    Company including, but not limited to any act of God, act of Governmental Authorities,
                                    legislative changes, malicious third party attacks on the Platform, electrical power fluctuations
                                    or failures, electrical or electromagnetic stress, environmental factors, failure of public
                                    utilities,
                                    labour unrest, hostilities between nations, war, riot, civil commotions, civil war, insurrection,
                                    blockades, import or export regulations or embargoes, national emergency, rainstorms,
                                    earthquake, fires, flooding, explosion or other exceptional weather conditions or natural
                                    disasters, acts of terrorism, accidents, sabotages, strikes, shortages in material or supply,
                                    destruction of service facilities, infectious diseases, epidemics, as well as travel restrictions
                                    or
                                    travel warnings due to any such events or any unforeseen change in circumstances.
                                </li>
                                <li><span>Specific Relief:</span> You acknowledge that your unauthorised use of the Services and/or the
                                    Platform may result in irreparable damage and injury to the Company and/or its affiliates or
                                    licensors for which money damages would be inadequate. Consequently, in the event of such
                                    unauthorised use, we and our affiliates and/or licensors (as applicable) shall have the right, in
                                    addition to any other legal remedies available, to seek an immediate injunction against you
                                    prohibiting any further use of the Services and/or the Platform.
                                </li>
                                <li>
                                    <span> Waiver:</span> Failure of the Company to require performance of any provision of these Terms
                                    shall
                                    not affect the Company's right to full performance thereof at any time thereafter, and any waiver
                                    by the Company of a breach of any provision hereof shall not constitute a waiver of a similar
                                    breach in the future or of any other breach. No waiver shall be effective unless in writing and
                                    duly executed by an authorized representative of the Company.
                                </li>
                                <li>
                                    <span>Entire Agreement:</span> These Terms including all related agreements and policies
                                    incorporated by
                                    reference herein, constitute the entire agreement between you and the Company related to the
                                    subject matter hereof.
                                </li>
                                <li>
                                    <span>Independent Rights:</span> Each of the rights of the Company are independent, cumulative and
                                    without prejudice to all other rights available to it under law, equity or otherwise, and the
                                    exercise or non-exercise of any such rights shall not prejudice or constitute a waiver of any
                                    other right by the Company, whether under these Terms or otherwise. The rights of the
                                    Company with respect to any matter conferred under any provision of these Terms shall be in
                                    addition to any other rights conferred under any other provision of these Terms, in law or in
                                    equity.
                                </li>
                                <li>
                                    <span>Severability:</span> If any provision of these Terms is held to be illegal, invalid or
                                    unenforceable under
                                    present or future laws such provision shall be fully severable. Thereafter, these Terms shall be
                                    construed and enforced as if such illegal, invalid, or unenforceable provision had never
                                    comprised a part of these Terms and the remaining provisions of these Terms shall remain in
                                    full force and effect and shall not be affected by the illegal, invalid or unenforceable provision
                                    or
                                    by its severance from these Terms. Furthermore, in lieu of each such illegal, invalid or
                                    unenforceable provision, we shall add as a part of these Terms, a provision as similar in terms
                                    to such illegal, invalid or unenforceable provision as may be possible and be legal, valid and
                                    enforceable.
                                </li>
                                <li>
                                    <span>Evidence:</span> Subject to the Applicable Laws of evidence, you hereby agree not to object
                                    to
                                    admission of these Terms as evidence in legal proceedings.
                                </li>
                                <li>
                                    <span>Proof:</span> Any data in our systems can be used as proof for things like orders, requests
                                    and
                                    anything else that relates to your use of our Services and the Platform. This data can be used
                                    in legal proceedings, in the same way as any written document.
                                </li>
                                <li>
                                    <span>Assignment:</span> These Terms and agreement evidenced by it is personal to you and is not
                                    assignable or transferable by you. We may assign, transfer or delegate any of our rights and
                                    obligations hereunder to any Person without your consent.
                                </li>
                                <li>
                                    <span>No Agency:</span> No agency, partnership, joint venture, or employment relationship is
                                    created as a
                                    result of these Terms and neither party has any authority of any kind to bind the other in any
                                    respect.
                                </li>
                                <li>
                                    <span>Notices:</span> Any notices, requests and other communications required or permitted
                                    hereunder to
                                    be provided by the User to the Company shall be in writing, in English language, and shall be
                                    given by hand against written acknowledgement or receipt, or sent by registered mail, or by
                                    facsimile followed by a confirmation letter by registered mail, or by email followed by a
                                    confirmation of receipt. All notices will be deemed to have been duly given when received by
                                    the Company. Unless otherwise specified in these Terms, electronic notices should be sent to
                                    the Company at [●]. The Company will send its notices to the Users by way of an email at the
                                    email address provided by the Users.
                                </li>
                            </ol>
                        </li>
                        <h2>Contact</h2>
                        <p>You may contact us at the following address:</p>
                        <h2>Bestriptech Solution Private Limited</h2>
                        <p>[Insert Address]</p>
                        <p>Effective Date: July [●], 2019</p>
                        <p>Copyright 2019: Bestriptech Solution Private Limited. All rights reserved. No part of Company's
                            Platform may be copied, reproduced, modified, or distributed in any form or manner without the prior
                            written permission of Company.
                        </p>
                    </ol>
            </section>
        )
    }

}
export default Terms