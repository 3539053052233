import React from 'react';
import { render } from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import MainPage from './App';
import Reducer from './Store';
import ReactGA from 'react-ga';
import 'antd-mobile/dist/antd-mobile.less'; 

ReactGA.initialize('UA-144064030-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const store = createStore(Reducer);

const App = () => (
  <MainPage/>
);

render(<Provider store={store}><App /></Provider>, window.document.getElementById('root'));
